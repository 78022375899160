const DisclaimerText = () => {
  return (
    <>
     <span className="modal-text-strong">Health and Safety Warnings</span>
          <br />
          <br />
          To reduce the risk of personal injury, discomfort or property damage,
          please read the warnings below carefully before using the provided
          headset. The headset produces an immersive experience that can
          distract you and can completely block your perception of your actual
          surroundings.&nbsp;
          <strong>
            Please remain seated at all times while using this headset
          </strong>
          .
          <br />
          <br />
          <span className="modal-text-strong">Not a Medical Device</span>
          <br />
          <br />
          The headset and accessories are not medical devices, and are not
          intended to diagnose, treat, cure, or prevent any disease.
          <br />
          <br />
          <span className="modal-text-strong">
            Pre-Existing Medical Conditions
          </span>
          <br />
          <br />
          Consult with your doctor before using the headset if you are pregnant,
          elderly, have pre-existing binocular vision abnormalities or
          psychiatric disorders, or suffer from a heart condition or other
          serious medical condition.
          <br />
          <br />
          <span className="modal-text-strong">
            Interference with Medical Devices
          </span>
          <br />
          <br />
          The headset may contain magnets or components that emit radio waves,
          which could affect the operation of nearby electronics, including
          cardiac pacemakers, hearing aids and defibrillators. If you have a
          pacemaker or other implanted medical device, do not use the headset
          and controller without first consulting your doctor or the
          manufacturer of your medical device. Maintain a safe distance between
          the headset and controller and your medical devices. Stop using the
          headset if you observe a persistent interference with your medical
          device.
          <br />
          <br />
          <span className="modal-text-strong">Seizures</span>
          <br />
          <br />
          Some people may experience severe dizziness, seizures, eye or muscle
          twitching or blackouts triggered by light flashes or patterns, and
          this may occur while they are watching TV, playing video games or
          experiencing virtual reality, even if they have never had a seizure or
          blackout before or have no history of seizures or epilepsy. Such
          seizures are more common in children and young people. Anyone who
          experiences any of these symptoms should discontinue use of the
          headset and see a doctor. If you previously have had a seizure, loss
          of awareness, or other symptom linked to an epileptic condition you
          should see a doctor before using the headset.
          <br />
          <br />
          <span className="modal-text-strong">Discomfort</span>
          <br />
          <br />
          Immediately discontinue using the headset if any of the following
          symptoms are experienced: seizures; loss of awareness; eye strain; eye
          or muscle twitching; involuntary movements; altered, blurred, or
          double vision or other visual abnormalities; dizziness;
          disorientation; impaired balance; impaired hand-eye coordination;
          excessive sweating; increased salivation; nausea; lightheadedness;
          discomfort or pain in the head or eyes; drowsiness; fatigue; or any
          symptoms similar to motion sickness.
          <br />
          <br />
          Just as with the symptoms people can experience after they disembark a
          cruise ship, symptoms of virtual reality exposure can persist and
          become more apparent hours after use. These post-use symptoms can
          include the symptoms above, as well as excessive drowsiness and
          decreased ability to multi-task. These symptoms may put you at an
          increased risk of injury when engaging in normal activities in the
          real world.
          <br />
          <br />
          Using the headset may make your muscles, joints, neck, hands, or skin
          hurt. If any part of your body becomes tired or sore while using the
          headset, or if you feel symptoms such as tingling, numbness, burning
          or stiffness, stop and rest for several hours before using it again.
          If you continue to have any of the above symptoms or other discomfort
          during or after use, stop use and see a doctor.
          <br />
          <br />
          <span className="modal-text-strong">Contagious Conditions</span>
          <br />
          <br />
          Do not use this headset if you have contagious conditions (like pink
          eye), infections, or diseases, particularly of the eyes, skin, or
          scalp.
          <br />
          <br />
          <br />
          <span className="modal-text-strong">TERMS OF USE</span>
          <br />
          <br />
          <br />
          <br />
          <span className="modal-text-strong">Effective Date: 10/4/2022</span>
          <br />
          <br />
          These Terms of Use (or “Terms”) govern your access to and use of the
          website and services owned, controlled, or provided by Even Health LLC
          (“Even Health,” “we,” “our,” or “us”), whether hosted by Even Health
          or a third party, including your use of our websites
          (www.evenhealth.com; www.mycabana.health) and our related
          applications, mobile applications, products, and services
          (collectively referred to as the “Services”). In the event you are
          using or accessing the Services on a free trial basis, all of the
          Terms herein will also govern that free trial for Services.
          <br />
          <br />
          NOTICE REGARDING DISPUTE RESOLUTION: THESE TERMS CONTAIN A BINDING
          ARBITRATION PROVISION AND CLASS ACTION WAIVER PROVISION (SEE SECTION 9
          BELOW) THAT GOVERN HOW CLAIMS YOU AND WE MAY HAVE AGAINST EACH OTHER
          ARE RESOLVED AND WHICH WILL, SUBJECT TO LIMITED EXCEPTIONS, REQUIRE
          YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING ARBITRATION UNLESS
          YOU OPT-OUT IN ACCORDANCE WITH SECTION 9 (E). UNLESS YOU OPT-OUT OF
          ARBITRATION: (A) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST
          US ON AN INDIVIDUAL BASIS, NOT AS PART OF ANY CLASS OR REPRESENTATIVE
          ACTION OR PROCEEDING AND (B) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
          (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN
          INDIVIDUAL BASIS.
          <br />
          <br />
          Please review these Terms of Use carefully before using our Services.
          By using and/or accessing our Services, you signify your assent to
          both these Terms and the Even Health Privacy Policy
          (https://www.evenhealth.com/privacy-policy) (“Privacy Policy”). If you
          do not agree to these Terms or the Privacy Policy, you are not
          permitted to access, enter, or use any part of the Services.
          <br />
          <br />
          <span className="modal-text-strong">INTENDED USE</span>
          <br />
          <br />
          The Even Health Services are intended for general informational use.
          THE SERVICES ARE NOT INTENDED FOR USE IN AN EMERGENCY. IF YOU ARE
          EXPERIENCING A MEDICAL EMERGENCY, PLEASE CALL 911 OR GO TO THE NEAREST
          EMERGENCY ROOM.
          <br />
          <br />
          <span className="modal-text-strong">SCOPE OF TERMS</span>
          <br />
          <br />
          We may provide you with specific terms of use, privacy policies, and
          notices for third-party software, applications, and services that we
          make available to you through the Services. When you use Even Health
          Services, use of such third-party software and services is governed by
          the specific terms of use for those services.
          <br />
          <br />
          <span className="modal-text-strong">WHO MAY USE OUR SERVICES</span>
          <br />
          <br />
          In using our Services, you agree that you are at least 18 years old.
          Even Health does not intentionally collect any information from
          children under 13 years of age unless the child’s parent gives
          consent. If we do not believe that you meet the criteria for use of
          the Services, or if we believe you have not or cannot agree to the
          Terms of Use and Privacy Policy for the Services, we may terminate
          your ability to access the Services, in our sole discretion.
          <br />
          <br />
          <span className="modal-text-strong">USER CONDUCT</span>
          <br />
          <br />
          You may access and use the Services only for lawful purposes in
          accordance with these Terms. Even Health is under no obligation to
          enforce these Terms on your behalf against another user; however, we
          encourage you to let us know if you believe another user has violated
          these Terms or has otherwise engaged in prohibited or illegal conduct.
          <br />
          <br />
          In using our Services, you agree not to (and will not assist,
          encourage, or enable others) to:
          <br />
          Violate any applicable federal, state, local, or international law or
          regulation.
          <br />
          Create, send, display, transmit, upload, download, use, or reuse any
          content which:
          <br />
          Contains any material which is defamatory, obscene, indecent, abusive,
          offensive, harassing, violent, hateful, inflammatory, or otherwise
          objectionable.
          <br />
          Infringes any patent, trademark, trade secret, copyright, or other
          intellectual property rights of any other person or entity; or
          <br />
          Violates or assists in the violation of legal rights (including the
          rights of publicity and privacy) of others or contains any material
          that could give rise to any civil or criminal liability under
          applicable laws or regulations, or that otherwise may be in conflict
          with these Terms and/or our Privacy Policy.
          <br />
          Transmit spam, chain letters, or any other type of unsolicited mass
          emails or conduct any commercial solicitation without Even Health’s
          written permission.
          <br />
          Reverse engineer, decompile, or disassemble or otherwise derive or
          determine or attempt to derive or determine the source code (or the
          underlying ideas, algorithms, structure or organization) of any part
          of the Services.
          <br />
          Modify, adapt, appropriate, reproduce, distribute, translate, create
          derivative works or adaptations of, publicly display, sell, trade, or
          in any way exploit Even Health Intellectual Property, except as may be
          expressly authorized by Even Health in a written contract or agreement
          between you and Even Health.
          <br />
          Record, process, harvest, collect, or mine information about other
          users.
          <br />
          Use the Services to transmit any computer viruses, worms, defects,
          Trojan horses, or other items of a destructive nature.
          <br />
          Use the Services to violate the security of any computer network,
          crack passwords or security encryption codes.
          <br />
          Remove, circumvent, disable, damage, or otherwise interfere with any
          security-related features or other restrictions of the Services.
          <br />
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of the Services, the server on which the Services is
          stored, or any server, computer, or database connected to the
          Services.
          <br />
          Otherwise attempt to interfere with the proper working of the
          Services.
          <br />
          INTELLECTUAL PROPERTY RIGHTS
          <br />
          <br />
          General; Copyrights. All content in any form is owned by Even Health
          or by third parties. It is protected by copyright laws. You are not
          permitted to copy, change or publish it without the owner&apos;s
          express permission. Any use of any content you receive through the
          Services without the permission of the owner is strictly prohibited.
          You are not allowed to download, &quot;mirror,&quot; encode or
          republish anything in the Services without our express permission. You
          also are not permitted to remove or modify any copyright, trademark,
          or other proprietary rights notice that appears in any portion of the
          Services or on any materials printed or copied from the Services.
          <br />
          <br />
          Trademarks. All logos and trademarks that appear in any portion of the
          Services or on any materials provided through the Services are either
          trademarks or registered trademarks of Even Health, our affiliates or
          licensors and may not be copied, imitated, or used, in whole or in
          part, without our prior written permission. All page headers, custom
          graphics, button icons, and scripts are service marks, trademarks
          and/or trade dress of Even Health and our affiliates, and may not be
          copied, imitated, or used, in whole or in part, without our prior
          written permission. Other trademarks, registered trademarks, product
          names, and company names or logos displayed on the Site are the
          property of their respective owners.
          <br />
          <br />
          Your Submissions. We do not claim ownership of any materials and other
          information, such as feedback, suggestions and ideas for new or
          improved products or services, that you may provide to us or post or
          upload to the Site (&quot;Submissions&quot;). However, by providing or
          submitting a Submission, you grant Even Health rights to use, display
          and modify the Submission. These rights include a grant to us, our
          affiliated companies and sublicensees of an irrevocable,
          non-exclusive, worldwide, royalty-free, perpetual license (including
          without limitation a copyright license), with the right to sublicense,
          reproduce, distribute, transmit, create derivative works of, publicly
          display and publicly perform the Submission and publish your name in
          connection with your Submission. You agree that you will not receive
          any compensation or payment, and you shall have no recourse against us
          for any alleged or actual infringement or misappropriation of any
          proprietary right in your Submissions to us. We are not required to
          post or use any Submission, and we may remove any Submission at any
          time in our sole discretion. By providing or submitting a Submission
          you warrant and represent that you own or otherwise control all of the
          rights to the Submission.
          <br />
          <br />
          Software. Any software, including any files, images generated by the
          software, code, and data accompanying the software (collectively,
          &quot;Software&quot;), used or accessible through the Services may be
          used by you solely to access and use the Services for the purposes
          expressly stated in these Terms or in the Services. You cannot use the
          Software or Services to compete with us. Even Health and our
          affiliates and licensors own all intellectual property rights in the
          Software. You may not copy, distribute, sell, modify, decompile,
          reverse engineer, disassemble or create derivative works from any
          Software.
          <br />
          <br />
          Any rights not expressly granted herein are reserved.
          <br />
          <br />
          <span className="modal-text-strong">
            COPYRIGHT POLICY & Complaints
          </span>
          <br />
          <br />
          Even Health expects you to respect copyright law. We will terminate
          access to the Services of any user who repeatedly infringes or is
          believed to be repeatedly infringing the rights of copyright holders
          or remove or disable access to material on the Services that infringes
          upon the copyright rights of others. Even Health also may, in our
          discretion, remove or disable links or references to an online
          location that contains infringing material or infringing activity.
          <br />
          <br />
          Digital Millennium Copyright Act. If you believe that your work has
          been used on the Services in any manner that constitutes copyright
          infringement, please notify Even Health&apos;s designated copyright
          agent by written notice at: Even Health LLC c/o M. Fretwell at 1160
          Spa Rd, Suite 2B Annapolis, MD 21403 USA.
          <br />
          <br />
          Notice. In order to be effective, your notice must include the
          following information, as provided for under the Online Copyright
          Infringement Liability Limitation Act of the Digital Millennium
          Copyright Act, 17 U.S.C. 512(c)(3):
          <br />
          An electronic or physical signature of a person authorized to act on
          behalf of the owner of the copyright allegedly infringed;
          Identification of the copyright work you claim has been infringed, or,
          if multiple copyrighted works on our websites are covered by a single
          notification, a representative list of such works at that site;
          <br />
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material;
          <br />
          Your name, address, telephone number and, if available, an email
          address; A statement by you that you have a good faith belief that the
          disputed use of the material at issue is not authorized by the
          copyright owner, the agent of the copyright owner or the law; and
          <br />
          A statement by you that the information in this notification is
          accurate and a statement, under penalty of perjury, that you are the
          copyright owner of the material allegedly infringed or authorized to
          act on the copyright owner&apos;s behalf.
          <br />
          <br />
          All notices received by Even Health that are not in compliance with
          the foregoing procedure will receive no response from us.
          <br />
          <br />
          <span className="modal-text-strong">
            PRIVACY AND PERSONAL INFORMATION
          </span>
          <br />
          <br />
          Even Health collects certain information about you when you visit our
          websites or use our Services. We explain what information we collect
          and how we use such information in our Privacy Policy. Even Health’s
          Privacy Policy, as it may change from time to time, is a part of these
          Terms of Use and is incorporated herein by this reference. Even Health
          reserves the right to use and disclose your information in the manner
          consistent with the Privacy Policy.
          <br />
          <br />
          <span className="modal-text-strong">INDEMNIFICATION</span>
          <br />
          <br />
          You agree to indemnify and hold harmless Even Health, and any of Even
          Health’s affiliates, successors, assigns or licensees, together with
          any of their respective board members, officers, directors, and
          employees, against any damages, losses, liabilities, judgments, costs
          and expenses (including reasonable attorneys&apos; fees and costs)
          arising out of a claim by Even Health or any third party relating to
          your violation of these Terms of Use, your use of the Services, your
          Submissions and/or your use of any information obtained from the
          Services.
          <br />
          <br />
          <span className="modal-text-strong">
            ARBITRATION AND CLASS ACTION WAIVER
          </span>
          <br />
          <br />
          PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS,
          INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
          <br />
          <br />
          You and Even Health agree that these Terms affect interstate commerce
          and that the Federal Arbitration Act governs the interpretation and
          enforcement of these arbitration provisions. This Section is intended
          to be interpreted broadly and governs any and all disputes between us
          arising under or related to any aspect of this agreement or Even
          Health, whether based in contract, tort, statute, fraud,
          misrepresentation or any other legal theory; claims that arose before
          this agreement or any prior agreement (and claims that may arise after
          the termination of these Terms. The only disputes excluded from this
          broad prohibition are the litigation of certain intellectual property
          and small court claims, as provided below.
          <br />
          <br />
          By agreeing to these Terms, you agree to resolve any and all disputes
          with Even Health as follows:
          <br />
          <br />
          Initial Dispute Resolution. If you have any concerns regarding your
          use of the Services, you can reach Even Health at support@even.health
          and support@mycabana.health. Most concerns may be quickly resolved in
          this manner. Except for intellectual property claims and small claims
          court claims, the parties agree to use their best efforts to settle
          any dispute, claim, question, or disagreement directly through
          consultation with Even Health, and good faith negotiations shall be a
          condition to either party initiating a lawsuit or arbitration.
          <br />
          <br />
          Binding Arbitration. If the parties do not reach an agreed-upon
          solution at least sixty (60) days from the time informal dispute
          resolution is initiated under the “Initial Dispute Resolution”
          subsection above, then either party may initiate binding arbitration,
          subject to the terms set forth below. Specifically, all claims arising
          out of or relating to these Terms or Privacy Policy (including
          formation, performance and breach), the parties&apos; relationship
          with each other, and/or your use of the Services shall be finally
          settled by binding arbitration administered by JAMS in accordance with
          the JAMS Streamlined Arbitration Procedure Rules for claims that do
          not exceed $250,000 and the JAMS Comprehensive Arbitration Rules and
          Procedures for claims exceeding $250,000 in effect at the time the
          arbitration is initiated, excluding any rules or procedures governing
          or permitting class actions.
          <br />
          <br />
          The arbitrator, and not any federal, state, or local court or agency,
          shall have exclusive authority to resolve all disputes arising out of
          or relating to the interpretation, applicability, enforceability, or
          formation of these Terms or the Privacy Policy, including but not
          limited to any claim that all or any part of these Terms or Privacy
          Policy is void or voidable. The arbitrator shall be empowered to grant
          whatever relief would be available in a court under law or in equity.
          The arbitrator&apos;s award shall be written and shall be binding on
          the parties and may be entered as a judgment in any court of competent
          jurisdiction. To start an arbitration, you must do the following: (a)
          write a Demand for Arbitration that includes a description of the
          claim and the amount of damages you seek to recover (you may find a
          copy of a Demand for Arbitration at www.jamsadr.com); (b) send three
          copies of the Demand for Arbitration, plus the appropriate filing fee,
          to the applicable JAMS Resolution Center, which can be found at
          http://www.jamsadr.com/locations; and (c) send one copy of the Demand
          for Arbitration to Even Health LLC c/o M. Fretwell at 1160 Spa Rd,
          Suite 2B Annapolis, MD 21403 USA.
          <br />
          <br />
          YOU AND EVEN HEALTH UNDERSTAND THAT, ABSENT THIS MANDATORY ARBITRATION
          PROVISION, EACH PARTY WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A
          JURY TRIAL. YOU AND EVEN HEALTH FURTHER UNDERSTAND THAT, IN SOME
          INSTANCES, THE COSTS OF ARBITRATION COULD EXCEED THE COSTS OF
          LITIGATION AND THE RIGHT TO DISCOVERY MAY BE MORE LIMITED IN
          ARBITRATION THAN IN COURT.
          <br />
          <br />
          Class Action Waiver. You and we further agree that any arbitration
          shall be conducted in our individual capacities only and not as a
          plaintiff or class member in any class action or other representative
          action, and the parties expressly waive their right to file a class
          action or seek relief on a class basis. If there is a final judicial
          determination that applicable law precludes enforcement of this
          Section&apos;s limitations as to a particular remedy, then that remedy
          (and only that remedy) must be severed from the arbitration and may be
          sought in court. The parties agree, however, that any adjudication of
          remedies not subject to arbitration shall be stayed pending the
          outcome of any arbitrable claims and remedies.
          <br />
          <br />
          Exception for Litigation of Intellectual Property and Small Claims
          Court Claims. Notwithstanding the parties&apos; decision to resolve
          all disputes through arbitration, either party may bring enforcement
          actions, validity determinations, or claims arising from or relating
          to theft, piracy, or unauthorized use of intellectual property in
          state or federal court or in the U.S. Patent and Trademark Office to
          protect its intellectual property rights (&quot;intellectual property
          rights&quot; means patents, copyrights, moral rights, trademarks, and
          trade secrets, but not privacy or publicity rights). Either party may
          also seek relief in a small claims court for disputes or claims within
          the scope of that court’s jurisdiction. Either party may also seek a
          declaratory judgment or other equitable relief in a court of competent
          jurisdiction regarding whether a party&apos;s claims are time-barred
          or may be brought in small claims court in your state and county of
          residence. Seeking such relief shall not waive a party&apos;s right to
          arbitration under this agreement.
          <br />
          <br />
          30-Day Right to Opt Out. You have the right to opt out and not be
          bound by the arbitration and class action waiver provisions set forth
          above by sending notice of your decision to opt out to
          compliance@even.health with the subject line, &quot;ARBITRATION AND
          CLASS ACTION WAIVER OPT-OUT.&quot; The notice must be sent within
          thirty (30) days of your first use of the Services; otherwise, you
          shall be bound to arbitrate disputes in accordance with the terms of
          those paragraphs. If you opt out of these arbitration provisions, Even
          Health also will not be bound by them.
          <br />
          <br />
          Changes to This Section. Any amendments to this section of the Terms
          of Use will become effective thirty (30) days after such notice.
          Changes to this section will otherwise apply prospectively only to
          claims arising after the thirtieth (30th) day. If a court or
          arbitrator decides that this subsection on &quot;Changes to This
          Section&quot; is not enforceable or valid, then this subsection shall
          be severed from the section entitled &apos;Arbitration and Class
          Action Waiver,&apos; and the court or arbitrator shall apply the first
          Arbitration and Class Action Waiver section in existence after you
          began using the Services.
          <br />
          <br />
          <span className="modal-text-strong">NO MEDICAL ADVICE</span>
          <br />
          <br />
          The information provided through the Even Health Services is designed
          to support, not replace, any relationship that may exist between you
          and your health care providers. All information and content provided
          by Even Health in connection with the Services is intended to be for
          general information purposes only and should not be considered a
          substitute for individual medical advice, diagnosis, or treatment.
          <br />
          <br />
          NOTHING STATED OR POSTED ON THE EVEN HEALTH WEBSITE OR PROVIDED
          THROUGH THE SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE,
          THE PRACTICE OF MEDICINE OR THE PROVISION OF MEDICAL CARE, NOR IS IT
          INTENDED IN ANY WAY TO CREATE A PROVIDER-PATIENT RELATIONSHIP AS
          DEFINED BY STATE OR FEDERAL LAW. USERS SHOULD SEEK THE ADVICE OF A
          PHYSICIAN OR OTHER QUALIFIED HEALTH CARE PROVIDER WITH ANY QUESTIONS
          REGARDING A MEDICAL CONDITION, AND FOR DIAGNOSIS AND TREATMENT. USERS
          SHOULD NOT USE THE SERVICES FOR EMERGENCY MEDICAL NEEDS. YOU AGREE
          THAT OPINIONS EXPRESSED BY PROVIDERS IN CONNECTION WITH THE SERVICES
          AND SERVICES ARE THEIR OWN AND NOT THOSE OF EVEN HEALTH AND WILL NOT
          BE USED IN ANY LEGAL DISPUTE AGAINST EVEN HEALTH, INCLUDING BUT NOT
          LIMITED TO LITIGATION, ARBITRATION, CLAIMS FOR DISABILITY BENEFITS,
          CLAIMS FOR WORKER’S COMPENSATION, AND/OR PROFESSIONAL MALPRACTICE
          CLAIMS.
          <br />
          <br />
          <span className="modal-text-strong">DISCLAIMER OF WARRANTY</span>
          <br />
          <br />
          In order to provide you with access to the Services, Even Health is
          unable to offer any warranties or make any representations about any
          benefits or opportunities which you may obtain through the access and
          use of the Services.
          <br />
          <br />
          WE HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. ACCESS TO THE SERVICES
          IS MADE AVAILABLE TO YOU “AS IS,” WITHOUT ANY WARRANTIES WHATSOEVER
          ABOUT THE NATURE, CONTENT OR ACCURACY (EITHER WHEN POSTED OR AS A
          RESULT OF THE PASSAGE OF TIME) OF ANY CONTENT MADE AVAILABLE THROUGH
          THE SERVICES, AND WITHOUT ANY REPRESENTATIONS OR GUARANTEES. IN
          ADDITION, WE MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES THAT
          YOUR ACCESS TO THE SERVICES WILL BE ACCESSIBLE CONTINUOUSLY AND
          WITHOUT INTERRUPTION, OR ERROR FREE. EVEN HEALTH IS NOT RESPONSIBLE
          FOR ANY DELAYS, INTERRUPTIONS, SERVICE OR EQUIPMENT FAILURES, OR FOR
          ANY TELECOMMUNICATIONS OR SERVICE PROVIDERS OUTSIDE THE REASONABLE
          CONTROL OF EVEN HEALTH.
          <br />
          <br />
          YOU ACKNOWLEDGE THAT EVEN HEALTH DOES NOT CONTROL THE TRANSFER OF DATA
          OVER THE INTERNET, AND THAT THE SERVICES WILL BE SUBJECT TO THE
          LIMITATIONS INHERENT IN THE USE OF THE INTERNET. IN THE EVENT OF ANY
          CONFLICT BETWEEN THIS SECTION AND OTHER TERMS OR PROVISIONS OF THIS
          AGREEMENT, THIS SECTION SHALL BE CONSTRUED TO TAKE PRECEDENCE.
          <br />
          <br />
          <span className="modal-text-strong">LIMITATION OF LIABILITY</span>
          <br />
          <br />
          IN ORDER TO PROVIDE YOU WITH ACCESS TO THE SERVICES, WE ARE UNABLE TO
          ACCEPT LIABILITY FOR ANY CONDUCT, ACTS OR OMISSIONS OCCURRING AS A
          RESULT OF YOUR USE OF SUCH SERVICES. THEREFORE, TO THE MAXIMUM EXTENT
          PERMITTED BY LAW, IN NO EVENT WILL EVEN HEALTH OR ITS EMPLOYEES AND
          AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR
          CONSEQUENTIAL DAMAGES, LOSSES, OR EXPENSES (INCLUDING, WITHOUT
          LIMITATION, LOST PROFITS, OR DAMAGES ARISING FROM LOST DATA OR
          BUSINESS INTERRUPTION) ARISING OUT OF OR IN ANY WAY CONNECTED WITH
          YOUR USE OF THE SERVICES, EVEN IF EVEN HEALTH HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, ON ANY THEORY OF LIABILITY (INCLUDING
          CONTRACT, TORT INCLUDING NEGLIGENCE).
          <br />
          <br />
          EVEN HEALTH SHALL NOT BE HELD RESPONSIBLE FOR ANY ACTION TAKEN THAT IS
          BASED ON THE INFORMATION PRESENTED IN THE EVEN HEALTH SERVICES. EVEN
          HEALTH EXPRESSLY DISCLAIMS ANY LIABILITY, WHETHER BASED IN CONTRACT,
          TORT, STRICT LIABILITY OR OTHERWISE, FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY
          WAY CONNECTED WITH ACCESS TO OR USE OF THE SERVICES, EVEN IF EVEN
          HEALTH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING
          BUT NOT LIMITED TO RELIANCE BY ANY PARTY ON ANY CONTENT OBTAINED
          THROUGH THE USE OF THE SERVICES, THE INABILITY TO USE THE SERVICES OR
          ANY ERRORS OF OMISSIONS IN THE CONTENT OF THE SERVICES.
          <br />
          <br />
          ALL CLAIMS MUST BE BROUGHT WITHIN SIX (6) MONTHS OF THE DATE OF THE
          FIRST EVENT GIVING RISE TO A CLAIM OR THEY WILL BE DEEMED FOREVER
          WAIVED. TO THE EXTENT LIABILITY CANNOT BE EXCLUDED OR DISCLAIMED, OUR
          LIABILITY IS LIMITED TO PROVEN DIRECT DAMAGES NOT TO EXCEED FIVE US
          DOLLARS ($5 USD).
          <br />
          <br />
          <span className="modal-text-strong">TERMINATION OF USE</span>
          <br />
          <br />
          Even Health may in its sole discretion terminate your access to the
          Services at any time, with or without notice. If you violate any
          provision of these Terms of Use, Even Health has the right to
          determine the action that will be taken, which may include (but not be
          limited to):
          <br />
          <br />
          Immediate termination of your right to use the Services. This
          termination may be temporary or permanent.
          <br />
          Immediate removal of any content posted by you to the Services.
          <br />
          Issuance of a warning or other communication to you.
          <br />
          Referral to law enforcement agencies for further action.
          <br />
          We will not be liable for any damages which you may suffer as a result
          of the foregoing actions. You may cease your use of the Services at
          any time.
          <br />
          <br />
          <span className="modal-text-strong">MODIFICATION</span>
          <br />
          <br />
          We reserve the right to modify the Services at any time (including by
          limiting or discontinuing certain features of the Services) without
          notice to you. We may also change or remove content or information
          from our Services at any time. We will have no liability whatsoever on
          account of any change to the Services or any suspension or termination
          of your access to or use of the Services.
          <br />
          <br />
          Even Health may revise and update these Terms of Use from time to time
          in our sole discretion. All changes are effective immediately when
          Even Health posts them and apply to all access to and use of the
          Services thereafter. However, any changes to the dispute resolution
          provisions set out in Section 9 of these Terms will not apply to any
          disputes for which the parties have actual notice on or before the
          date the change is posted on the Services. Your continued use of the
          Services following the posting of revised Terms of Use means that you
          accept and agree to the changes. You are expected to check this page
          frequently so you are aware of any changes, as they are binding on
          you.
          <br />
          <br />
          <span className="modal-text-strong">
            LINKS TO OTHER MATERIALS OR SITES
          </span>
          <br />
          <br />
          The Even Health Services may contain information provided by third
          parties. The Services may also contain hyperlinks that take you
          outside of our Services or link to external articles and videos and
          other linked sites (collectively, “Linked Sites”). Linked Sites are
          not under our control. We are not responsible in any way for the
          Linked Sites. Your use of such Linked Sites and your associated rights
          and obligations are governed by the separate terms of use and privacy
          policies, if any, of those Linked Sites.
          <br />
          <br />
          We provide third party content and hyperlinks to Linked Sites to you
          only as a convenience. Even Health does not endorse or recommend the
          third party content, the Linked Sites, the quality or accuracy of the
          content, or any products and services offered by the third parties,
          and no association with any third parties should be assumed. You agree
          that we have made no representations or warranties regarding such
          third party content and Linked Sites, and further agree that neither
          we nor our affiliates shall have any liability to you whatsoever for
          or relating to any third party material, data, information and/or
          Linked Sites. Your use of third party content and Linked Sites is at
          your own risk.
          <br />
          <br />
          <span className="modal-text-strong">
            CONSENT TO ELECTRONIC COMMUNICATIONS
          </span>
          <br />
          <br />
          By using the Services, you consent to receiving certain electronic
          communications from us. You agree that any notices, agreements,
          disclosures, or other communications that we send to you
          electronically will satisfy any legal communication requirements,
          including that those communications be in writing.
          <br />
          <br />
          <span className="modal-text-strong">GEOGRAPHIC RESTRICTIONS</span>
          <br />
          <br />
          The owner of the Services is based in the State of Maryland in the
          United States. We provide this Services for use only by persons
          located in the United States. We make no claims that the Services or
          any of its content is accessible or appropriate outside of the United
          States. Access to the Services may not be legal by certain persons or
          in certain countries. If you access the Services from outside the
          United States, you do so on your own initiative and are responsible
          for compliance with local laws.
          <br />
          <br />
          <span className="modal-text-strong">GOVERNING LAW</span>
          <br />
          <br />
          These Terms shall be governed by and construed in accordance with the
          laws of the State of Maryland, USA without regard to its conflicts of
          laws provision. The United Nations Convention on Contracts for the
          International Sale of Goods shall not apply. To the extent that the
          arbitration provisions set forth in Section 9 do not apply, or if you
          have provided notice to opt out of arbitration, the parties agree that
          any litigation between them shall be filed exclusively in the state or
          federal courts located in Anne Arundel County, Maryland. In the event
          of litigation relating to these Terms or the Privacy Policy, the
          parties agree to waive, to the maximum extent permitted by law, any
          right to a jury trial, except where a jury trial waiver is not
          permissible under applicable law.
          <br />
          <br />
          <span className="modal-text-strong">WAIVER AND SEVERABILITY</span>
          <br />
          <br />
          No waiver by the Even Health of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Even Health to assert a right or provision under these
          Terms shall not constitute a waiver of such right or provision.
          <br />
          <br />
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
          <br />
          <br />
          <span className="modal-text-strong">CONTACT INFORMATION</span>
          <br />
          <br />
          You may contact us by sending correspondence to Even Health LLC
          <span className="modal-text-strong">
            c/o M. Fretwell at 1160 Spa Rd, Suite 2B Annapolis, MD 21403 USA
          </span>
          or by emailing us at:
          <span className="modal-text-strong">compliance@even.health.</span>
          </>
  );
};

export default DisclaimerText;
