import './PodPage.css';

import Home from '../components/Home';
import PreFeeling from '../components/PreFeeling';
import SelectScene from '../components/SelectScene';
import SelectAudio from '../components/SelectAudio';
import PostFeeling from '../components/PostFeeling';
import Finished from '../components/Finished';
import Disclaimer from '../components/Disclaimer';
import { usePodContext, Screen } from '../PodContext';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import mixpanel from '../../mixpanel/mixpanel';
import Faq from '../components/Faq';
import Meditation from '../components/meditation/Meditation';

const PodPage = () => {
  const location = useLocation()
  const podContext = usePodContext()

  useEffect(() => {
    mixpanel.track_pageview({
      screen: location.pathname,
      audio: podContext.audio?.name,
      device: podContext.device,
      postFeeling: podContext.postFeeling,
      preFeeling: podContext.preFeeling,
      scene: podContext.scene?.title,
    });
  }, [location, podContext])

  return (
    <div className="pod-container">
      <Routes>
        <Route path={Screen.HOME} element={<Home />} />
        <Route path={Screen.DISCLAIMER} element={<Disclaimer />} />
        <Route path={Screen.PRE_FEELING} element={<PreFeeling />} />
        <Route path={Screen.SELECT_SCENE} element={<SelectScene />} />
        <Route path={Screen.SELECT_AUDIO} element={<SelectAudio />} />
        <Route path={Screen.MEDITATION} element={<Meditation />} />
        <Route path={Screen.POST_FEELING} element={<PostFeeling />} />
        <Route path={Screen.FINISHED} element={<Finished />} />
        <Route path={Screen.FAQ} element={<Faq />} />
      </Routes>
    </div>
)
}

export default PodPage