import { Link, useNavigate } from 'react-router-dom';
import './Header.css'

const Header = ({ title, progress, noBack = false }: {
  noBack?: boolean
  title?: string,
  progress?: number
}) => {
  const navigate = useNavigate()

  const goBack = (e: any) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <>
    {!noBack && <Link className="vr-header-back" to="" onClick={goBack}>
      <img src={process.env.PUBLIC_URL + '/assets/misc/arrow-left.svg'} className="vr-header-back-icon" alt="arrow" /> Back
    </Link>}
    <div className="vr-header-container">
      <div className="vr-header-logo">
        <img src={'/assets/logos/logo-white.svg'} alt="Cabana logo"  />
      </div>
      {progress && <img src={`/assets/progress-bars/progress_line_step_${progress}.svg`} alt="progress bar" />}
      <div className="vr-header-title">{title}</div>
    </div>
    </>
  )
}

export default Header;