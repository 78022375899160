import { createContext, useContext, useEffect, useMemo, useState } from "react";

export enum Device {
  TABLET = "Tablet",
  HEADSET = "Headset"
}

export enum Screen {
  HOME = "/",
  DISCLAIMER = "/disclaimer",
  PRE_FEELING = "/pre-feeling",
  SELECT_SCENE = "/select-scene",
  SELECT_AUDIO = "/select-audio",
  READY = "/ready",
  MEDITATION = "/meditation",
  POST_FEELING = "/post-feeling",
  FINISHED = "/finished",
  FAQ = "/faq"
}

const deviceFromPath: () => Device = () => {
  switch(window.location.pathname) {
    case '/headset': return Device.HEADSET;
    default: return Device.TABLET;
  }
}

export interface IPodContext {
  device: Device, setDevice: (device: Device) => void,
  preFeeling: number, setPreFeeling: (preFeeling: number) => void,
  scene: any, setScene: (scene: any) => void,
  audio: any, setAudio: (audio: any) => void,
  postFeeling: number, setPostFeeling: (preFeeling: number) => void,
  resetChoices: () => void
}

const PodContext = createContext<IPodContext>({
  device: Device.TABLET,
  setDevice: () => {},

  preFeeling: NaN,
  setPreFeeling: () => {},

  scene: null,
  setScene: () => {},

  audio: null,
  setAudio: () => {},

  postFeeling:  NaN,
  setPostFeeling: () => {},

  resetChoices: () => {}
});

const usePodContext = () => useContext(PodContext)

const PodContextProvider = ({ children }: { children: any }) => {
  const [device, setDevice] = useState<Device>(deviceFromPath());
  const [preFeeling, setPreFeeling] = useState(Number(localStorage.getItem('preFeeling')) || NaN)
  //@ts-ignore
  const [scene, setScene] = useState<any>(JSON.parse(localStorage.getItem('scene')))
  //@ts-ignore
  const [audio, setAudio] = useState<any>(JSON.parse(localStorage.getItem('audio')))
  const [postFeeling, setPostFeeling] = useState(Number(localStorage.getItem('postFeeling')) || NaN)
  
  const resetChoices = () => {
    setPreFeeling(NaN)
    setScene(null)
    setAudio(null)
    setPostFeeling(NaN)
  }

  const context: IPodContext = useMemo(() => ({
    device, setDevice,
    preFeeling, setPreFeeling,
    scene, setScene,
    audio, setAudio,
    postFeeling, setPostFeeling,
    resetChoices
  }), [device, preFeeling, scene, audio, postFeeling])

  useEffect(() => localStorage.setItem('preFeeling', String(preFeeling)), [preFeeling])
  //@ts-ignore
  useEffect(() => localStorage.setItem('scene', scene ? JSON.stringify(scene) : null), [scene])
  //@ts-ignore
  useEffect(() => localStorage.setItem('audio', audio ? JSON.stringify(audio) : null), [audio])
  useEffect(() => localStorage.setItem('postFeeling', String(postFeeling)), [postFeeling])

  return (
    <PodContext.Provider value={context}>
      {children}
    </PodContext.Provider>
  )
}

export { PodContextProvider, usePodContext }