import { useProgress } from "uni-fiber";
import { Device, Screen, usePodContext } from "../../PodContext";

import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import { Scene } from "./Scene";
import "./Meditation.css";
import { VRButton, XR } from "@react-three/xr";
import { SceneVr } from "./SceneVr";
import Button from "../Button";

const Meditation = () => {
  const { device, scene, audio } = usePodContext();
  const navigate = useNavigate();

  const progress = useProgress();
  const [loaded, setLoaded] = useState(false);
  const [ready, setReady] = useState(false)



  useEffect(() => {
    if (progress.loaded === progress.total && progress.progress === 100) {
      setTimeout(() => setLoaded(true), 1500);
    }
  }, [progress]);

  const meditationAudio = useRef<HTMLAudioElement>(null);
  const backgroundAudio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (loaded && device !== Device.HEADSET) {
      setReady(true)
    }
  }, [device, loaded, setReady]);

  useEffect(() => {
    if(ready) {
      meditationAudio.current?.play();
      backgroundAudio.current?.play();
    }
  }, [ready])

  const audioEnded = useCallback(() => {
    setTimeout(() => {
      navigate(Screen.POST_FEELING);
    }, 2500);
  }, [navigate]);

  const canvas = (
    <Canvas
    gl={{
      outputEncoding: THREE.sRGBEncoding,
      toneMapping: THREE.NoToneMapping,
      sortObjects: true,
      alpha: true,
      antialias: true,
    }}>
    <ambientLight />
    <pointLight position={[0, 18, 40]} />
    <Suspense fallback={null}>
      <Scene
        activeScene={scene}
        loading={!ready}
      />
    </Suspense>
    </Canvas>
  )

  const headsetCanvas = (
    <Canvas
    gl={{
      outputEncoding: THREE.sRGBEncoding,
      toneMapping: THREE.NoToneMapping,
      // sortObjects: true,
      // alpha: true,
      antialias: true,
    }}
    dpr={[1, 1]}
  >
    <XR>    <ambientLight />
    <pointLight position={[0, 18, 40]} />
    <Suspense fallback={null}>
      <SceneVr
        activeScene={scene}
        loading={!ready}
      />
    </Suspense></XR>
    </Canvas>
  )

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      <div className="meditation-loading">
        <div>Preparing your experience...{progress.total ? Math.floor(progress.loaded / progress.total * 100) : 0}%</div>
        <div className="meditation-button" style={{ opacity: loaded && device === Device.HEADSET ? 100 : 0}}>
          <VRButton onClick={() => setReady(true)}>I'm ready</VRButton>
        </div>
      </div>
      <div
        className="meditation-scene-container"
        style={{ visibility: ready && device !== Device.HEADSET ? "visible" : "hidden" }}
      >
        {device === Device.HEADSET ? headsetCanvas : canvas}
        <audio
          ref={meditationAudio}
          src={audio.audioUrl}
          onEnded={audioEnded}
        />
        <audio ref={backgroundAudio} src={scene.backgroundAudioUrl} loop />
        <div className="meditation-exit-button-container">
          <Button
            text="I'm done"
            filled
            onClick={() => navigate(Screen.POST_FEELING)}
          />
        </div>
      </div>
    </div>
  );
};

export default Meditation;
