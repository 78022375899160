import Perlin from 'perlin.js'
import React, {forwardRef} from 'react'
import * as THREE from "three";

Perlin.seed(Math.random())

export const BlobGeometry = forwardRef((props, ref) => {
    return (
        <primitive ref={ref} object={props.mesh} position={props.mesh.position.clone().sub(new THREE.Vector3(0, 0.1, 0))}/>
    )
})
