import { Link } from "react-router-dom";
import { Screen } from "../PodContext";
import "./Footer.css"

const Footer = () => {
  return (<div className="pod-footer">
    <Link to={Screen.FAQ}>
      <img src={'/assets/faq-buttons/faq-button-black.svg'} alt="question" />
    </Link>
  </div>)
}

export default Footer;