const FaqText = () => {
  return (
    <ul>
      <li className="faq-page-list-item-title">
        What should I expect when I use the Cabana pod?
      </li>
      <p className="faq-page-list-item-text">
        This Cabana pod is intended to be a private, safe space for you to
        mentally reset during your workday. You will use the tablet provided to
        select your setting and choose your guided exercise. Using the tablet or
        VR headset, you will be transported to your virtual oasis. Expect to
        spend 5-10 minutes in the Cabana pod, enjoying your personalized
        experience. Don’t forget to submit feedback after you’re finished!
      </p>
      <li className="faq-page-list-item-title">
        How do I reserve time in the Cabana pod?
      </li>
      <p className="faq-page-list-item-text">
        For now, there is no need to make a reservation to use the Cabana pod.
        If the Cabana pod is empty, it’s available to be used.
      </p>
      <li className="faq-page-list-item-title">
        Is there a limit to how much time I can spend in the Cabana pod?
      </li>
      <p className="faq-page-list-item-text">
        While there is not a limit to how much time you can spend in the Cabana
        pod, we ask that you be considerate of others that may want to use it.
        Our exercises are about 4-10 minutes each.
      </p>
      <li className="faq-page-list-item-title">
        Do I have to use the headset/tablet in the pod? Can I meditate on my
        own?
      </li>
      <p className="faq-page-list-item-text">
        While we recommend using the tablet or VR headset for the full
        experience, you do not need to use either to meditate. Whichever way you
        choose to meditate and reset, please be mindful of others and only use
        the Cabana pod for its intended use cases.
      </p>
      <li className="faq-page-list-item-title">
        Are there any risks to using the VR headset?
      </li>
      <p className="faq-page-list-item-text">
        There may be some risks associated with using VR headsets in general.
        You can review our terms here:&nbsp;
        <span
          /* href="https://www.mycabana.health/vr-headset-disclaimer" */
          className="faq-page-list-link"
        >
          https://www.mycabana.health/vr-headset-disclaimer
        </span>
      </p>
      <li className="faq-page-list-item-title">
        How often is the Cabana pod sanitized?
      </li>
      <p className="faq-page-list-item-text">
        Each Cabana pod is thoroughly cleaned and sanitized every frequently.
        Feel free to use the wipes provided before and after your time in the
        pod. If you notice that this Cabana pod needs our attention, please
        email us at&nbsp;
        <span
          /* href="mailto:support@mycabana.health" */
          className="faq-page-list-mail"
        >
          support@mycabana.health
        </span>
        .
      </p>
      <li className="faq-page-list-item-title">
        Who do I contact if something is wrong with the Cabana pod?
      </li>
      <p className="faq-page-list-item-text">
        Feel free to email us at&nbsp;
        <span
          /* href="mailto:support@mycabana.health" */
          className="faq-page-list-mail"
        >
          support@mycabana.health
        </span>{" "}
        if you notice anything in the Cabana pod needing our attention.
        Additionally, we’d love to hear your thoughts on ways we can make your
        experience better.
      </p>
      <li className="faq-page-list-item-title">
        Where do I go for more wellness content?
      </li>
      <p className="faq-page-list-item-text">
        Looking for more? Visit us at&nbsp;
        <span
          /* href="https://www.mycabana.health" */
          className="faq-page-list-link"
        >
          mycabana.health
        </span>
        . We offer live groups daily and have a robust library of on demand
        content designed to connect healthcare professionals from across the
        country around shared issues and areas for self-improvement in mental
        wellness. If you are an Ochsner employee, log in or sign up for an
        account using your access code.
      </p>
    </ul>
  );
};

export default FaqText;
