import "./Disclaimer.css";
import Header from "./Header";
import Button from "./Button";
import GoHomeTimer from "./GoHomeTimer";
import { useNavigate } from "react-router-dom";
import { Screen } from "../PodContext";
import DisclaimerText from "./DisclaimerText";

const Disclaimer = () => {
  const navigate = useNavigate();

  return (
    <>
      <GoHomeTimer />
      <Header title="Disclaimer" progress={1} />
      <div className="pod-disclaimer-container">
        <div className="pod-disclaimer-scroll">
          <div className="pod-disclaimer-text">
            <DisclaimerText />
          </div>
        </div>
      <div>
      <Button
          text="I agree"
          filled
          onClick={() => navigate(Screen.PRE_FEELING)}
        />
        </div>
        </div>
    </>
  );
};

export default Disclaimer;
