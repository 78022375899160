import React from 'react';
import './App.css';

import ReactGA from 'react-ga';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

import { PodContextProvider } from './refactor/PodContext';
import PodPage from './refactor/pages/PodPage';
import ErrorPage from './refactor/pages/ErrorPage';
import { HashRouter } from 'react-router-dom';

ReactGA.initialize('G-RCSPBZ6J1P');

Bugsnag.start({
  apiKey: 'b8e7b5728925ae47af331c07d26b8453',
  plugins: [new BugsnagPluginReact()],
  releaseStage: 'production'
})
BugsnagPerformance.start({ apiKey: 'b8e7b5728925ae47af331c07d26b8453' })

const App = () => {
  /*
  const [disclaimerOpened, setDisclaimerOpened] = useState<boolean>(true);
  const [feelingValue, setFeelingValue] = useState<string>('2');
  const [sceneSelected, setSceneSelected] = useState<string>('');
  const [audioSelected, setAudioSelected] = useState<string>('');
  const [deviceSelected, setDeviceSelected] = useState<string>('');
  const [scenesList, setScenesLst] = useState<any>(scenes);
  const [activeScene, setActiveScene] = useState<any>(null);
  const [previousPage, setPreviousPage] = useState('');
  const pageVariants = {
    in: {
      opacity: 0.5,
      x: '+100vw',
    },
    out: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0.5,
      x: '-100vw',
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.5,
  };

  const memoizedContext = React.useMemo(() => ({
    disclaimerOpened,
    setDisclaimerOpened,
    feelingValue,
    setFeelingValue,
    sceneSelected,
    setSceneSelected,
    audioSelected,
    setAudioSelected,
    deviceSelected,
    setDeviceSelected,
    pageVariants,
    pageTransition,
    scenesList,
    setScenesLst,
    activeScene,
    setActiveScene,
    previousPage,
    setPreviousPage,
  }), [
    disclaimerOpened,
    setDisclaimerOpened,
    feelingValue,
    setFeelingValue,
    sceneSelected,
    setSceneSelected,
    audioSelected,
    setAudioSelected,
    deviceSelected,
    pageVariants,
    pageTransition,
    scenesList,
    setScenesLst,
    activeScene,
    setActiveScene,
    previousPage,
    setPreviousPage,
  ]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const { setConfiguration } = useConfiguration()
  const [configurationState, setConfigurationState] = useState<'loading' | 'ready' | 'error'>('loading')

  useEffect(() => {
    fetch(dynamicConfigurationUrl)
    .then(response => response.json())
    .then(data => {
      setConfigurationState('ready')
      setConfiguration(data)
    })
    .catch(e => {
      console.error(e)
      setConfigurationState('error')
    })
  }, [setConfiguration])

  if(configurationState === 'loading') return <div className="loading-page"></div>
  if(configurationState === 'error') return <h1>'Error'</h1>
    */

  const ErrorBoundary = (Bugsnag.getPlugin('react') as BugsnagPluginReactResult).createErrorBoundary(React)
  return (
      <div className="App">
        <PodContextProvider>
          <HashRouter>
          {/*<ErrorBoundary FallbackComponent={ErrorPage}>*/}
          <PodPage />
          {/*</ErrorBoundary>*/}
          </HashRouter>
          </PodContextProvider>
      </div>
  )
}

export default App;
