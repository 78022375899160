import * as THREE from 'three';

export const scenes = [
  {
    title: 'Forest',
    preview: '/assets/scenery-backgrounds/forest-scenery.png',
    order: 1,
    available: true,
    camera_Z: 3,
    backgroundAudioUrl: '/assets/sounds/backgrounds/forest.mp3',
    volume: 0.5,
    modelTablet: 'scenes/forest/models/forest_07_01.glb',
    modelVr: 'scenes/forest/models/forest_07_01.glb',
    modelRotation: [0, -Math.PI / 2, 0],
    panoTablet: 'scenes/forest_tablet/11199_00000_3_copy.jpg',
    panoVr: 'scenes/forest/11199_00000_3_copy.jpg',
    animationNames: [],
    animalsActions: [
      { name: 'Drink', duration: 10 },
      { name: 'Drink.001', duration: 10 },
      { name: 'Eat', duration: 10 },
      { name: 'Idle_1', duration: 5 },
      { name: 'Idle_2', duration: 5 },
      { name: 'Idle_3', duration: 5 },
      { name: 'Lie', duration: 20 },
    ],
    waterMesh: 'SM_Water_01',
    waterConfigTablet: {
      width: 160,
      height: 90,
      speed: 50,
      shift_X: -5,
      shift_Y: -12,
      shift_Z: 35,
      textureConf: {
        textureWidth: 256,
        textureHeight: 256,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0xbceff7,
        distortionScale: 0.2,
      },
    },
    waterConfigVr: {
      width: 160,
      height: 90,
      speed: 50,
      shift_X: -5,
      shift_Y: -12,
      shift_Z: 35,
      textureConf: {
        textureWidth: 256,
        textureHeight: 256,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0xbceff7,
        distortionScale: 0.2,
      },
    },
    frustumCulledObjectNamesTablet: ['Moose_calf_1'],
    frustumCulledObjectNamesVr: [],
    vrCameraPosition: [-1.32, 5.44, -1],
    vrCameraY: 0.15,
    position: [-0.477, 0.845, -0.153],
    grassMesh: 'SM_Ground_02',
    grassConfig: {
      isRotatedGrass: true,
      strands: 30000,
      size: 0.7,
      speed: 0.01,
      shift: new THREE.Vector3(0, 0.45, 0),
      colorA: '#2f3601',
      colorB: '#6e9141',
    },
    grassConfigVr: {
      isRotatedGrass: true,
      strands: 5000,
      size: 0.7,
      speed: 0.01,
      shift: new THREE.Vector3(0, 0.45, 0),
      colorA: '#2f3601',
      colorB: '#6e9141',
    },
    sparkles: [
      {
        position: [-20, 0, 20],
        count: 100,
        speed: 1,
        opacity: 1,
        color: new THREE.Color(1, 1, 0.8),
        size: 10,
        scale: 45,
        noise: 1,
      }, {
        position: [-20, 0, 20],
        count: 50,
        speed: 2,
        opacity: 1,
        color: new THREE.Color(0.9, 0.85, 0.2),
        size: 10,
        scale: 45,
        noise: [0.12, 1, 0.5],
      }, {
        position: [0, 0, -35],
        count: 50,
        speed: 2,
        opacity: 1,
        color: new THREE.Color(1, 1, 0.7),
        size: 10,
        scale: 25,
        noise: [0.12, 1, 0.5],
      }, {
        position: [0, 0, 0],
        count: 50,
        speed: 2,
        opacity: 1,
        color: new THREE.Color(1, 1, 0.7),
        size: 10,
        scale: 70,
        noise: [0.12, 1, 0.5],
      },
    ],
    sparklesVr: [
      {
        position: [-20, 0, 20],
        count: 50,
        speed: 1,
        opacity: 1,
        color: new THREE.Color(1, 1, 0.8),
        size: 10,
        scale: 45,
        noise: 1,
      }, {
        position: [-20, 0, 20],
        count: 25,
        speed: 2,
        opacity: 1,
        color: new THREE.Color(0.9, 0.85, 0.2),
        size: 10,
        scale: 45,
        noise: [0.12, 1, 0.5],
      }, {
        position: [0, 0, -35],
        count: 25,
        speed: 2,
        opacity: 1,
        color: new THREE.Color(1, 1, 0.7),
        size: 10,
        scale: 25,
        noise: [0.12, 1, 0.5],
      }, {
        position: [0, 0, 0],
        count: 25,
        speed: 2,
        opacity: 1,
        color: new THREE.Color(1, 1, 0.7),
        size: 10,
        scale: 70,
        noise: [0.12, 1, 0.5],
      },
    ],
    clouds: [
      // Front
      {
        color: '0xFFFFFF',
        position: [-3, -3, 22],
        speed: 1,
        opacity: 0.2,
        width: 10,
      },
      {
        color: '0xffffff',
        position: [-20, 2, 18],
        speed: 1,
        opacity: 0.5,
        width: 10,
      },
      // Sky
      {
        color: '0xFFFFFF',
        position: [0, 36, 10],
        speed: 0.4,
        opacity: 0.3,
        width: 20,
        segments: 10,
      },
      {
        color: '0xFFFFFF',
        position: [0, 28, -10],
        speed: 0.3,
        opacity: 0.3,
        width: 20,
        segments: 10,
      },
    ],
    customItems: [
      {
        mesh: ['Moose_calf_1'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/animals/2Moose_calf_002Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/animals/Moose_calf_002Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //Animals

      {
        mesh: ['SM_Bark_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/bark_01/SM_Bark_004Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/bark_01/SM_Bark_004Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Bark_01
      {
        mesh: ['SM_Bark_02'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/bark_02/SM_Bark_003Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/bark_02/SM_Bark_003Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Bark_02
      {
        mesh: ['SM_Fern_01'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          blending: THREE.NormalBlending,
          aoMapIntensity: 0.85,
          side: THREE.DoubleSide,
        },
        textures: [
          {
            type: 'map',
            url: 'scenes/forest_tablet/textures/fern_01/map_fern_01_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'alphaMap',
            url: 'scenes/forest_tablet/textures/fern_01/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/fern_01/lightmap_fern_01_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/fern_01/ao_fern_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_01
      {
        mesh: ['SM_Fern_02'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          blending: THREE.NormalBlending,
          aoMapIntensity: 0.7,
          side: THREE.DoubleSide,
        },
        textures: [
          {
            type: 'alphaMap',
            url: 'scenes/forest_tablet/textures/fern_02/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'map',
            url: 'scenes/forest_tablet/textures/fern_02/map_fern_02.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/fern_02/lightmap_fern_02_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/fern_02/ao_fern_02.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_02
      {
        mesh: ['SM_Fern_03'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          aoMapIntensity: 0.7,
          side: THREE.DoubleSide,
          blending: THREE.NormalBlending,
        },
        textures: [
          {
            type: 'alphaMap',
            url: 'scenes/forest_tablet/textures/fern_03/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'map',
            url: 'scenes/forest_tablet/textures/fern_03/map_fern_03.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/fern_03/lightmap_fern_03_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/fern_03/ao_fern_03.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_03
      {
        mesh: ['SM_Fern_04'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          aoMapIntensity: 0.7,
          side: THREE.DoubleSide,
          blending: THREE.NormalBlending,
        },
        textures: [
          {
            type: 'alphaMap',
            url: 'scenes/forest_tablet/textures/fern_04/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'map',
            url: 'scenes/forest_tablet/textures/fern_04/map_fern_04.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/fern_04/lightmap_fern_04_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/fern_04/ao_fern_04.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_04
      {
        mesh: ['SM_Stone_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_01/SM_Stone_01Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_01/SM_Stone_01Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_1
      {
        mesh: ['SM_Stone_02'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_02/SM_Stone_02Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_02/SM_Stone_02Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_2
      {
        mesh: ['SM_Stone_03'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_03/SM_Stone_03Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_03/SM_Stone_03Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_3
      {
        mesh: ['SM_Stone_04'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_04/SM_Stone_04Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_04/SM_Stone_04Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_4
      {
        mesh: ['SM_Stone_07'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_07/SM_Stone_07Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_07/SM_Stone_07Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_7
      {
        mesh: ['SM_Stone_08'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_08/SM_Stone_08Corona_Beauty-copy_6.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_08/SM_Stone_08Corona_AO-copy_3.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_8
      {
        mesh: ['SM_Stone_09'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_09/SM_Stone_09Corona_Beauty-copy_6.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_09/SM_Stone_09Corona_AO copy_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_9
      {
        mesh: ['SM_Stone_10'],
        material: 'MeshBasicMaterial',
        material_properties: {
          aoMapIntensity: 0.9,
        },
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_10/SM_Stone_10Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_10/SM_Stone_10Corona_AO copy_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_10
      {
        mesh: ['SM_Stone_11'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest_tablet/textures/stone_11/SM_Stone_11Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest_tablet/textures/stone_11/SM_Stone_11Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_11
    ],
    customItemsVr: [
      {
        mesh: ['SM_Bark_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/bark_01/SM_Bark_004Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/bark_01/SM_Bark_004Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Bark_01
      {
        mesh: ['SM_Bark_02'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/bark_02/SM_Bark_003Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/bark_02/SM_Bark_003Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Bark_02
      {
        mesh: ['SM_Fern_01'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          blending: THREE.NormalBlending,
          aoMapIntensity: 0.85,
          side: THREE.DoubleSide,
        },
        textures: [
          {
            type: 'map',
            url: 'scenes/forest/textures/fern_01/map_fern_01_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'alphaMap',
            url: 'scenes/forest/textures/fern_01/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/fern_01/lightmap_fern_01_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/fern_01/ao_fern_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_01
      {
        mesh: ['SM_Fern_02'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          blending: THREE.NormalBlending,
          aoMapIntensity: 0.7,
          side: THREE.DoubleSide,
        },
        textures: [
          {
            type: 'alphaMap',
            url: 'scenes/forest/textures/fern_02/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'map',
            url: 'scenes/forest/textures/fern_02/map_fern_02.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/fern_02/lightmap_fern_02_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/fern_02/ao_fern_02.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_02
      {
        mesh: ['SM_Fern_03'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          aoMapIntensity: 0.7,
          side: THREE.DoubleSide,
          blending: THREE.NormalBlending,
        },
        textures: [
          {
            type: 'alphaMap',
            url: 'scenes/forest/textures/fern_03/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'map',
            url: 'scenes/forest/textures/fern_03/map_fern_03.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/fern_03/lightmap_fern_03_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/fern_03/ao_fern_03.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_03
      {
        mesh: ['SM_Fern_04'],
        material: 'MeshStandardMaterial',
        material_properties: {
          transparent: true,
          aoMapIntensity: 0.7,
          side: THREE.DoubleSide,
          blending: THREE.NormalBlending,
        },
        textures: [
          {
            type: 'alphaMap',
            url: 'scenes/forest/textures/fern_04/fern_o1pacity_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'map',
            url: 'scenes/forest/textures/fern_04/map_fern_04.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/fern_04/lightmap_fern_04_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/fern_04/ao_fern_04.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Fern_04
      {
        mesh: ['SM_Stone_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_01/SM_Stone_01Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_01/SM_Stone_01Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_1
      {
        mesh: ['SM_Stone_02'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_02/SM_Stone_02Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_02/SM_Stone_02Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_2
      {
        mesh: ['SM_Stone_03'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_03/SM_Stone_03Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_03/SM_Stone_03Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_3
      {
        mesh: ['SM_Stone_04'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_04/SM_Stone_04Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_04/SM_Stone_04Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_4
      {
        mesh: ['SM_Stone_07'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_07/SM_Stone_07Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_07/SM_Stone_07Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_7
      {
        mesh: ['SM_Stone_08'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_08/SM_Stone_08Corona_Beauty-copy_6.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_08/SM_Stone_08Corona_AO-copy_3.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_8
      {
        mesh: ['SM_Stone_09'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_09/SM_Stone_09Corona_Beauty-copy_6.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_09/SM_Stone_09Corona_AO copy_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_9
      {
        mesh: ['SM_Stone_10'],
        material: 'MeshBasicMaterial',
        material_properties: {
          aoMapIntensity: 0.9,
        },
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_10/SM_Stone_10Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_10/SM_Stone_10Corona_AO copy_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_10
      {
        mesh: ['SM_Stone_11'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'lightMap',
            url: 'scenes/forest/textures/stone_11/SM_Stone_11Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/forest/textures/stone_11/SM_Stone_11Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, //SM_Stone_11
    ],
  },
  {
    title: 'Beach',
    preview: '/assets/scenery-backgrounds/forest-scenery.png',
    order: 2,
    available: true,
    camera_Z: 3,
    backgroundAudioUrl: '/assets/sounds/backgrounds/beach.mp3',
    volume: 0.5,
    modelTablet: 'scenes/beach/models/scene_13.glb',
    modelVr: 'scenes/beach/models/scene_13.glb',
    modelRotation: [0, 0, 0],
    panoTablet: 'scenes/beach_tablet/REn_2023.jpg',
    panoVr: 'scenes/beach/REn_2023.jpg',
    frustumCulledObjectNamesTablet: [],
    frustumCulledObjectNamesVr: [],
    vrCameraPosition: [0, 0, 0],
    vrCameraY: 0,
    position: [-0.6, 0.845, -0.153],
    grassMesh: '',
    grassConfig: {},
    grassConfigVr: {},
    animationNames: [{ name: 'yahta', duration: 5 }, { name: 'veslo', duration: 0 }],
    animalsActions: [],
    waterMesh: 'SM_Water_01',
    waterConfigTablet: {
      width: 160,
      height: 90,
      speed: 20,
      shift_X: -5,
      shift_Y: -1.95,
      shift_Z: 35,
      textureConf: {
        textureWidth: 512,
        textureHeight: 512,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0xbceff7,
        distortionScale: 0.2,
      },
    },
    waterConfigVr: {
      width: 160,
      height: 90,
      speed: 20,
      shift_X: -5,
      shift_Y: -1.95,
      shift_Z: 35,
      textureConf: {
        textureWidth: 512,
        textureHeight: 512,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0xbceff7,
        distortionScale: 0.2,
      },
    },
    sparkles: [
      {
        position: [0, 0, 5],
        count: 60,
        speed: 1,
        opacity: 0.6,
        color: new THREE.Color(1, 1, 0.8),
        size: 5,
        scale: 20,
        noise: [0.12, 1, 0.5],
      },
      {
        position: [0, 0, 5],
        count: 40,
        speed: 1,
        opacity: 0.7,
        color: new THREE.Color(1, 1, 1),
        size: 3,
        scale: 15,
        noise: [0.12, 1, 0.5],
      },
      {
        position: [0, 0, 5],
        count: 100,
        speed: 0.5,
        opacity: 0.5,
        color: new THREE.Color(1, 1, 5),
        size: 5,
        scale: 35,
        noise: [0.12, 1, 0.5],
      },
    ],
    sparklesVr: [],
    clouds: [
      {
        color: '0xFFFFFF',
        position: [15, 20, -5],
        speed: 1,
        opacity: 0.1,
        width: 10,
        segments: 10,
      },
      {
        color: '0xFFFFFF',
        position: [-15, 20, -2],
        speed: 1,
        opacity: 0.2,
        width: 15,
        segments: 10,
      },
    ],
    customItems: [
      {
        mesh: ['sand_low_poly1'],
        material: 'MeshPhongMaterial',
        mesh_transform: {
          position: [0, 0.001, 0.298]
        },
        material_properties: {
          color: '#000000',
          // visible: false,
          // shininess: 150,
          roughness: 1,
          transparent: true,
          opacity: 1,
          // blending: THREE.NormalBlending,
          // visible: false,
        },
        textures: [
          // {
          //   type: 'map',
          //   url: 'scenes/beach/textures/Sand/Sand_diffuse_14.jpg',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(20, 20),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          // {
          //   type: 'SpecularMap',
          //   url: 'scenes/beach/textures/Sand/33322111111.png',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(1000, 1000),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          // {
          //   type: 'normalMap',
          //   url: 'scenes/beach/textures/Sand/Sand_normal_1.jpg',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(10, 10),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          // {
          //   type: 'roughnessMap',
          //   url: 'scenes/beach/textures/Sand/Sand_roughness_1.jpg',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(10, 10),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Sand/opacityMap_sand_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          // {
          //   type: 'lightMap',
          //   url: 'scenes/beach/textures/Sand/Light_sand.jpg',
          //   texture_properties: {
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          {
            type: 'alphaMap',
            url: 'scenes/beach_tablet/textures/Sand/opacityMap_sand_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // sand_low_poly
      {
        mesh: ['Yahta'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Yahta/Yahta2_yahta_color_BaseColor_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach/textures/Yahta/Yahta2_yahta_color_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/Yahta/Yahta2_yahta_color_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Yahta/yachta_lightmap_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Yahta/yachta_ao_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, // Yahta
      {
        mesh: ['Planks'],
        mesh_transform: {
          position: [0, -29.236, -0.806]
        },
        material: 'MeshPhongMaterial',
        material_properties: {
          shininess: 30,
        },
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/tropinka/Planks_Wood_planks_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach_tablet/textures/tropinka/Planks_Wood_planks_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach_tablet/textures/tropinka/Planks_Wood_planks_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, // Tropinka
      {
        mesh: ['Small_boaut', 'left_veslo', 'right_veslo'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Small_boat/Mesh_Base_Color3.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach_tablet/textures/Small_boat/Mesh_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // small_bout
      {
        mesh: ['Rocks_low_poly'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Rocks_asset/ZAPEC.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Rocks_asset/Low_poly_Rocks_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Rocks_asset/Low_poly_Rocks_asset_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Rocks_asset

      {
        mesh: ['Mesh008', 'Mesh008_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_1_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_1_low
      {
        mesh: ['Mesh010', 'Mesh010_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_2_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_2_low
      {
        mesh: ['Mesh011', 'Mesh011_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_3_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_3_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_3_low
      {
        mesh: ['Mesh012', 'Mesh012_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_4_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/serfs_textures/Serf_4_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_4_low

      {
        mesh: ['Lounger_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_1_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_1
      {
        mesh: ['Lounger_2'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_2_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_2
      {
        mesh: ['Lounger_3'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_3_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_3_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_3
      {
        mesh: ['Lounger_4'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_4_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_4_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_4
      {
        mesh: ['Lounger_5'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_5_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_5_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_5
      {
        mesh: ['Lounger_6'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_6_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Lounger_tex/Lounger_6_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_6

      {
        mesh: ['Umrella_1_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          // {
          //   type: 'aoMap',
          //   url: 'scenes/beach/textures/Umbrellas/Umrella_1_AO_2.jpg',
          //   texture_properties: {
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_1_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_1_low
      {
        mesh: ['Umrella_2_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_2_AO_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_2_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_2_low
      {
        mesh: ['Umrella_3_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_3_AO_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_3_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_3_low
      {
        mesh: ['Umrella_4_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_4_AO_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/Umbrellas/Umrella_4_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_4_low

      {
        mesh: ['Wood001'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/chairs/Wood_chear.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly1_Light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Chair_1_wood
      {
        mesh: ['Fabrick_1', 'Fabrick_2'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/chairs/Fabric_color.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly1_Light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach_tablet/textures/chairs/Fabric_normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Chair_1_fabric

      {
        mesh: ['Wood002'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/chairs/Wood_chear.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly2_light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Chair_2_wood
      {
        mesh: ['Fabrick_1001', 'Fabrick_2001'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach_tablet/textures/chairs/Fabric_color.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly2_light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach_tablet/textures/chairs/Chear_low_poly2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach_tablet/textures/chairs/Fabric_normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],


      }, // Chair_2_fabric
      {
        mesh: ['SM_waterPlane_01'],
        mesh_properties: { visible: false },
        mesh_transform: {
          position: [0, -10, 0]
        },
      }
    ],
    customItemsVr: [
      {
        mesh: ['sand_low_poly1'],
        material: 'MeshPhongMaterial',

        mesh_transform: {
          position: [0, 0.001, 0.298]
        },
        mesh_properties: {
        },
        material_properties: {
          color: '#000000',
          // visible: false,
          // shininess: 150,
          roughness: 1,
          transparent: true,
          opacity: 1,
          // depthTest: false,
          // blending: THREE.NormalBlending,
          // visible: false,
        },
        textures: [
          // {
          //   type: 'map',
          //   url: 'scenes/beach/textures/Sand/Sand_diffuse_14.jpg',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(20, 20),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          // {
          //   type: 'SpecularMap',
          //   url: 'scenes/beach/textures/Sand/33322111111.png',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(1000, 1000),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          // {
          //   type: 'normalMap',
          //   url: 'scenes/beach/textures/Sand/Sand_normal_1.jpg',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(10, 10),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          // {
          //   type: 'roughnessMap',
          //   url: 'scenes/beach/textures/Sand/Sand_roughness_1.jpg',
          //   texture_properties: {
          //     mapping: THREE.UVMapping,
          //     wrapS: THREE.RepeatWrapping,
          //     wrapT: THREE.RepeatWrapping,
          //     repeat: new THREE.Vector2(10, 10),
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Sand/opacityMap_sand_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          // {
          //   type: 'lightMap',
          //   url: 'scenes/beach/textures/Sand/Light_sand.jpg',
          //   texture_properties: {
          //     encoding: 3001,
          //     flipY: false,
          //   },
          // },
          {
            type: 'alphaMap',
            url: 'scenes/beach/textures/Sand/opacityMap_sand_01.jpg',
            texture_properties: {
              // encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // sand_low_poly
      {
        mesh: ['Yahta'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Yahta/Yahta2_yahta_color_BaseColor_1.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach/textures/Yahta/Yahta2_yahta_color_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/Yahta/Yahta2_yahta_color_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Yahta/yachta_lightmap_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Yahta/yachta_ao_01.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, // Yahta
      {
        mesh: ['Planks'],
        mesh_transform: {
          position: [0, -29.236, -0.806],
        },
        material: 'MeshPhongMaterial',
        material_properties: {
          shininess: 30,
        },
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/tropinka/Planks_Wood_planks_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach/textures/tropinka/Planks_Wood_planks_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/tropinka/Planks_Wood_planks_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      }, // Tropinka
      {
        mesh: ['Small_boaut', 'left_veslo', 'right_veslo'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Small_boat/Mesh_Base_Color3.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/Small_boat/Mesh_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // small_bout
      {
        mesh: ['Rocks_low_poly'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Rocks_asset/ZAPEC.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Rocks_asset/Low_poly_Rocks_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Rocks_asset/Low_poly_Rocks_asset_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Rocks_asset

      {
        mesh: ['Mesh008', 'Mesh008_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_1_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_1_low
      {
        mesh: ['Mesh010', 'Mesh010_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_2_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_2_low
      {
        mesh: ['Mesh011', 'Mesh011_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_3_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_3_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_3_low
      {
        mesh: ['Mesh012', 'Mesh012_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/serfs_textures/diffuse.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_4_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/serfs_textures/Serf_4_light_map.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Serf_4_low

      {
        mesh: ['Lounger_1'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_1_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_1
      {
        mesh: ['Lounger_2'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_2_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_2
      {
        mesh: ['Lounger_3'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_3_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_3_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_3
      {
        mesh: ['Lounger_4'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_4_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_4_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_4
      {
        mesh: ['Lounger_5'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_5_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_5_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_5
      {
        mesh: ['Lounger_6'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Lounger_tex/Wood_Lounders.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_6_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Lounger_tex/Lounger_6_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Lounger_6

      {
        mesh: ['Umrella_1_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_1_AO_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_1_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_1_low
      {
        mesh: ['Umrella_2_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_2_AO_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_2_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_2_low
      {
        mesh: ['Umrella_3_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_3_AO_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_3_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_3_low
      {
        mesh: ['Umrella_4_low'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_BaseColor.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'metalnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Metallic.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'roughnessMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_tex/Umbrella_1_Color_umbrella_1_Roughness.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_4_AO_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/Umbrellas/Umrella_4_Lightmap_2.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Umrella_4_low

      {
        mesh: ['Wood001'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/chairs/Wood_chear.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly1_Light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Chair_1_wood
      {
        mesh: ['Fabrick_1', 'Fabrick_2'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/chairs/Fabric_color.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly1_Light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly1_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach/textures/chairs/Fabric_normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Chair_1_fabric

      {
        mesh: ['Wood002'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/chairs/Wood_chear.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly2_light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
        ],
      }, // Chair_2_wood
      {
        mesh: ['Fabrick_1001', 'Fabrick_2001'],
        material: 'MeshStandardMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/beach/textures/chairs/Fabric_color.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly2_light.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/beach/textures/chairs/Chear_low_poly2_AO.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'normalMap',
            url: 'scenes/beach/textures/chairs/Fabric_normal.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],


      }, // Chair_2_fabric
      {
        mesh: ['SM_waterPlane_01'],
        mesh_properties: { visible: false },
        mesh_transform: {
          position: [0, -10, 0]
        },
      }
    ],
  },
  {
    title: 'Lake',
    preview: '/assets/scenery-backgrounds/lake-scenery.png',
    order: 3,
    available: true,
    camera_Z: 3,
    backgroundAudioUrl: '/assets/sounds/backgrounds/lake.mp3',
    volume: 0.5,
    modelTablet: 'scenes/lake/models/lake_04_01.glb',
    modelVr: 'scenes/lake/models/lake_04_01.glb',
    modelRotation: [0, 0, 0],
    panoTablet: 'scenes/lake_tablet/lake_pano.jpg',
    panoVr: 'scenes/lake/lake_pano.jpg',
    frustumCulledObjectNamesTablet: ['LowPoly_Hare_cub_1001', 'LowPoly_Hare_cub_1'],
    frustumCulledObjectNamesVr: [],
    position: [1.126, 4.256, -3.334],
    vrCameraPosition: [1.126, 4.256, -3.334],
    vrCameraY: -2.256,
    waterMesh: 'SM_Water_01',
    animationNames: [{ name: 'A_Boat_01', duration: 6 }],
    animalsActions: [
      { name: "Crouch_idle", duration: 10 },
      { name: "Drink", duration: 10 },
      { name: "Eat", duration: 10 },
      { name: "Idle_1", duration: 10 },
      { name: "Idle_2", duration: 10 },
      { name: "Idle_3", duration: 5 },
      { name: "Lie", duration: 10 },
    ],
    waterConfigTablet: {
      width: 100,
      height: 100,
      speed: 20,
      shift_X: 0,
      shift_Y: -0.85,
      shift_Z: 50,
      textureConf: {
        textureWidth: 256,
        textureHeight: 256,
        sunDirection: new THREE.Vector3(),
        sunColor: 0x5a2a1d,
        waterColor: 0x3e8b9c,
        distortionScale: 3.7,
      },
    },
    waterConfigVr: {
      width: 100,
      height: 100,
      speed: 20,
      shift_X: 0,
      shift_Y: -0.85,
      shift_Z: 50,
      textureConf: {
        textureWidth: 256,
        textureHeight: 256,
        sunDirection: new THREE.Vector3(),
        sunColor: 0x5a2a1d,
        waterColor: 0x3e8b9c,
        distortionScale: 3.7,
      },
    },
    grassMesh: 'SM_Ground_01',
    grassConfig: {
      isRotatedGrass: false,
      strands: 40000,
      size: 0.7,
      speed: 0.006,
      shift: new THREE.Vector3(0, 0.5, 0),
      colorA: '#2c3306',
      colorB: '#4d6e34',
    },
    grassConfigVr: {
      isRotatedGrass: false,
      strands: 5000,
      size: 0.7,
      speed: 0.006,
      shift: new THREE.Vector3(0, 0.5, 0),
      colorA: '#2c3306',
      colorB: '#4d6e34',
    },
    sparkles: [
      {
        position: [0, 0, 0],
        count: 200,
        speed: 2,
        opacity: 0.8,
        color: new THREE.Color(1, 1, 0.8),
        size: 10,
        scale: 70,
        noise: [0.12, 1, 0.5],
      },
    ],
    sparklesVr: [
      {
        position: [0, 0, 0],
        count: 200,
        speed: 2,
        opacity: 0.8,
        color: new THREE.Color(1, 1, 0.8),
        size: 10,
        scale: 70,
        noise: [0.12, 1, 0.5],
      },
    ],
    clouds: [
      {
        color: '0xFFFFFF',
        position: [0, 0, 80],
        speed: 2,
        opacity: 0.3,
        width: 10,
        // segments: 40,
      },
      {
        color: '0xffffff',
        position: [35, 3, 80],
        speed: 1,
        opacity: 0.2,
        width: 10,
        // segments: 40,
      },
      {
        color: '0xffffff',
        position: [-35, 10, 80],
        speed: 1,
        opacity: 0.2,
        width: 10,
        // segments: 40,
      },
      // Sky >>>
      {
        color: '0xffffff',
        position: [-10, 32, 0],
        speed: 0.8,
        opacity: 0.1,
        width: 40,
        segments: 60,
      },
      {
        color: '0xffffff',
        position: [-35, 32, 15],
        speed: 0.8,
        opacity: 0.15,
        width: 20,
        segments: 60,
      },
    ],
    customItems: [
      {
        mesh: ['LowPoly_Hare_cub_1001', 'LowPoly_Hare_cub_1'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/lake/textures/animals/4LowPoly_Hare_cub_1Corona_Beauty copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: false,
            },
          },
          {
            type: 'aoMap',
            url: 'scenes/lake/textures/animals/LowPoly_Hare_cub_1Corona_AO copy.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      },

      {
        mesh: ['AM_Boat_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/lake_tablet/textures/boat_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      },
      {
        mesh: ['SM_Doc_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'aoMap',
            url: 'scenes/lake_tablet/textures/dock_ao_02 (2).jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/lake_tablet/textures/dock_lightmap_01 (3).jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      },
    ],
    customItemsVr: [
      {
        mesh: ['AM_Boat_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'map',
            url: 'scenes/lake/textures/boat_lightmap.jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      },
      {
        mesh: ['SM_Doc_01'],
        material: 'MeshBasicMaterial',
        material_properties: {},
        textures: [
          {
            type: 'aoMap',
            url: 'scenes/lake/textures/dock_ao_02 (2).jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
          {
            type: 'lightMap',
            url: 'scenes/lake/textures/dock_lightmap_01 (3).jpg',
            texture_properties: {
              encoding: 3001,
              flipY: true,
            },
          },
        ],
      },
    ],
  },
];

export const audios = [
  {
    id: '1',
    name: 'Boundary Exercise',
    duration: '5-min',
    order: 1,
    image: '/assets/scenery-backgrounds/beach-scenery.png',
    audioUrl: '/assets/sounds/meditations/Boundary_Exercise.mp3',
  },
  {
    id: '2',
    name: 'Breathing Exercise',
    duration: '4-min',
    order: 2,
    image: '/assets/scenery-backgrounds/beach-scenery.png',
    audioUrl: '/assets/sounds/meditations/Breathing_Exercise.mp3',
  },
  {
    id: '3',
    name: 'Cabana Muscle Tension',
    duration: '5-min',
    order: 3,
    image: '/assets/scenery-backgrounds/beach-scenery.png',
    audioUrl: '/assets/sounds/meditations/Cabana_Muscle_Tension.mp3',
  },
  {
    id: '4',
    name: 'Calming Meditation',
    duration: '6-min',
    order: 4,
    image: '/assets/scenery-backgrounds/beach-scenery.png',
    audioUrl: '/assets/sounds/meditations/Calming_Meditation.mp3',
  },
  {
    id: '5',
    name: 'Energy Boost',
    duration: '6-min',
    order: 5,
    image: '/assets/scenery-backgrounds/beach-scenery.png',
    audioUrl: '/assets/sounds/meditations/Energy_Boost.mp3',
  },
  {
    id: '6',
    name: 'Self-Compassion Mantra',
    duration: '5-min',
    order: 6,
    image: '/assets/scenery-backgrounds/beach-scenery.png',
    audioUrl: '/assets/sounds/meditations/Mantra_Self_Compassion.mp3',
  },
];

export const glasses = {
  // model: 'gadgets/models/glasses.glb',
  model: 'gadgets/models/glasses-transformed.glb',
};

export const tablet = {
  model: 'gadgets/models/tablet-transformed.glb',
};

export const quotes = [
  {
    quote: '"When you give joy to other people, you get more joy in return."',
    author: 'Eleanor Roosevelt'
  },
  {
    quote: '"The best way to cheer yourself up is to try to cheer somebody else up."',
    author: 'Mark Twain'
  },
  {
    quote: '"The best way to find yourself is to lose yourself in the service of others."',
    author: 'Mahatma Gandhi'
  },
  {
    quote: '"Be the reason someone smiles. Be the reason someone feels loved and believes in the goodness in people."',
    author: 'Roy T. Bennett'
  },
  {
    quote: '"Nothing is impossible. The word itself says \'I\'m possible!\'"',
    author: 'Audrey Hepburn'
  },
  {
    quote: '"Keep your face always toward the sunshine, and shadows will fall behind you."',
    author: 'Walt Whitman'
  },
  {
    quote: '"You define your own life. Don\'t let other people write your script."',
    author: 'Oprah Winfrey'
  },
  {
    quote: '"Spread love everywhere you go."',
    author: 'Mother Teresa'
  },
  {
    quote: '"Believe you can and you\'re halfway there."',
    author: 'Theodore Roosevelt'
  },
  {
    quote: '"In a gentle way, you can shake the world."',
    author: 'Mahatma Gandhi'
  },
  {
    quote: '"Try to be a rainbow in someone\'s cloud."',
    author: 'Maya Angelou'
  }
];
