import Feeling from "./Feeling";
import GoHomeTimer from "./GoHomeTimer";
import Header from "./Header";
import "./PreFeeling.css"
import { Screen, usePodContext } from "../PodContext";
import { useNavigate } from "react-router-dom";


const PreFeeling = () => {
  const { setPreFeeling } = usePodContext()
  const navigate = useNavigate()

  const selected = (choice: number) => {
    setPreFeeling(choice)
    navigate(Screen.SELECT_SCENE)
  }

  return (
    <>
    <GoHomeTimer />
    <Header title="Mood check-in" progress={2} />
    <div className="vr-pre-feeling-container">
      <Feeling selected={selected}/>
    </div>
    </>
  )
}

export default PreFeeling;