import Header from "./Header";
import "./SelectAudio.css"
import { audios } from '../../constants/app.config';
import GoHomeTimer from "./GoHomeTimer";
import { Screen, usePodContext } from "../PodContext";
import { useNavigate } from "react-router-dom";

const SelectAudio = () => {
  const { setAudio } = usePodContext()
  const navigate = useNavigate()

  const selected = (audio: any) => {
    setAudio(audio)
    navigate(Screen.MEDITATION)
  }

  return (<>
    <GoHomeTimer />
    <Header title="Select audio" progress={4} />
    <div className="vr-select-audio-container">
      <div className="vr-select-audio-options">
      {audios.map((audio: any) => (
        <div key={audio.name} className="vr-select-audio-option" onClick={() => selected(audio)}>
          <span>{audio.duration}</span>
          <span>{audio.name}</span>
        </div>
      ))}
      </div>
    </div>
  </>)
}

export default SelectAudio;