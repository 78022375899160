import './Home.css'
import Button from './Button'
import { Screen, usePodContext } from '../PodContext';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useEffect } from 'react';

const Home = () => {
  const navigate = useNavigate()
  const { resetChoices } = usePodContext()

  useEffect(resetChoices, [resetChoices])

  return (
    <div className="vr-home-container">
      <img src={process.env.PUBLIC_URL + '/assets/logos/logo-white.svg'} alt="logo" className="vr-home-logo" />
      <h1 className="vr-home-title">
        Relax with a
        <br />
        micro-vacation
      </h1>
      <Button text="Start" filled onClick={() => navigate(Screen.DISCLAIMER)} />
      <Footer />
    </div>
  )
}

export default Home;