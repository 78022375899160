import './Feeling.css'

const Feeling = ({selected}: {
  selected: (choice: number) => void
}) => {
  return (
    <div className="vr-feeling-container">
        <div className="vr-feeling-title">How are you feeling right now?</div>
        <div className="vr-feeling-options">
          <div className="vr-feeling-option" onClick={() => selected(1)}><img src={'/assets/mood-emojies/mood-1.svg'} alt="1" />1</div>
          <div className="vr-feeling-option" onClick={() => selected(2)}><img src={'/assets/mood-emojies/mood-2.svg'} alt="2" />2</div>
          <div className="vr-feeling-option" onClick={() => selected(3)}><img src={'/assets/mood-emojies/mood-3.svg'} alt="3" />3</div>
          <div className="vr-feeling-option" onClick={() => selected(4)}><img src={'/assets/mood-emojies/mood-4.svg'} alt="4" />4</div>
          <div className="vr-feeling-option" onClick={() => selected(5)}><img src={'/assets/mood-emojies/mood-5.svg'} alt="5" />5</div>
      </div>
    </div>
  )
}

export default Feeling;