import Header from "./Header";
import { scenes } from '../../constants/app.config'
import CN from 'classnames';
import "./SelectScene.css"
import GoHomeTimer from "./GoHomeTimer";
import { Screen, usePodContext } from "../PodContext";
import { useNavigate } from "react-router-dom";

const SelectScene = () => {
  const navigate = useNavigate()
  const { setScene } = usePodContext()

  const selected = (scene: any) => {
    setScene(scene)
    navigate(Screen.SELECT_AUDIO)
  }

  return (<>
    <GoHomeTimer />
    <Header title="Select scene" progress={3} />
    <div className="vr-select-scene-container">
      <div className="vr-select-scene-options">
      {scenes.map((scene: any) => (
        <div key={scene.title} className={CN(
          'vr-select-scene-option',
          { 'scenery-scene-image-forest': `${scene.title}` === 'Forest' },
          { 'scenery-scene-image-beach': `${scene.title}` === 'Beach' },
          { 'scenery-scene-image-lake': `${scene.title}` === 'Lake' },
        )} onClick={() => selected(scene)}>{scene.title}</div>
      ))}
      </div>
    </div>
  </>)
}

export default SelectScene;
