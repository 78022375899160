import * as THREE from 'three';
import {useCallback, useMemo, useRef} from 'react';
import {extend, useFrame, useLoader, useThree} from '@react-three/fiber';
import {Water} from 'three-stdlib'

extend({Water})

export const Ocean = (props) => {
    const ref = useRef()
    const gl = useThree((state) => state.gl)
    const waterNormals = useLoader(THREE.TextureLoader, 'scenes/other.textures/waternormals.jpeg')
    waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
    const geom = useMemo(() => new THREE.PlaneGeometry(+props.configuration.width, +props.configuration.height), [])
    const config = useMemo(
        () => ({
            ...props.configuration.textureConf,
            waterNormals,
            fog: false,
            format: gl.encoding
        }),
        [waterNormals]
    )

    useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta / props.configuration.speed))

    return <water ref={ref} args={[geom, config]} position={[props.configuration.shift_X, props.configuration.shift_Y, props.configuration.shift_Z]} rotation-x={-Math.PI / 2}/>
}
