import CN from "classnames"
import "./Button.css"

const Button = ({ onClick, text, filled, outlined, disabled }: {
  onClick: () => void,
  text?: string,
  filled?: boolean,
  outlined?: boolean,
  disabled?: boolean
}) => {
  return (
    <button
        type="button"
        className={CN(
          'button',
          { 'button-filled': filled },
          { 'button-filled-disabled': disabled },
          { 'button-outlined': outlined },
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
  )
}

export default Button