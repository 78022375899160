import { useEffect } from "react";
import { Screen } from "../PodContext";
import { useNavigate } from "react-router-dom";


const GoHomeTimer = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => navigate(Screen.HOME, {replace: true}), 1000 * 60 * 10);

    return () => clearTimeout(timeout)
  }, [navigate])

  return <></>
}

export default GoHomeTimer;