import Feeling from "./Feeling";
import GoHomeTimer from "./GoHomeTimer";
import Header from "./Header";
import "./PostFeeling.css"
import { Screen, usePodContext } from "../PodContext";
import { useNavigate } from "react-router-dom";
import mixpanel from "../../mixpanel/mixpanel";

const PostFeeling = () => {
  const { device, preFeeling, scene, audio, setPostFeeling } = usePodContext()
  const navigate = useNavigate()

  const selected = (choice: number) => {
    setPostFeeling(choice)

    mixpanel.track("Meditation Complete", {
      device,
      preFeeling,
      scene: scene.title,
      audio: audio.name,
      postFeeling: choice,
      moodChange: choice - preFeeling,
      moodChangePercent: (choice - preFeeling) / preFeeling
    })

    navigate(Screen.FINISHED)
  }

  return (
    <>
    <GoHomeTimer />
    <Header title="Tell us about your experience" noBack></Header>
    <div className="vr-post-feeling-container">
      <Feeling selected={selected}/>
    </div>
    </>
  )
}

export default PostFeeling;