import { useFrame, useThree } from '@react-three/fiber';
import { CameraTraveler } from './cameraTraveler/camera.traveler';
import * as React from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three-stdlib';
import { useTexture } from 'uni-fiber';

const createMaterial = () => {
  const material = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    // wireframe: true,
    uniforms: {
      resolution: {
        value: new THREE.Vector2(1024, 1024),
      },
      map: {
        value: new THREE.Texture(),
      },
      map2: {
        value: new THREE.Texture(),
      },

      placement: {
        value: new THREE.Vector3(),
      },
      placement2: {
        value: new THREE.Vector3(),
      },
      camera: {
        value: new THREE.Vector3(),
      },

      intensity: {
        value: 0,
      },
      intensity2: {
        value: 0,
      },
    },
    vertexShader: `
      varying highp vec3 worldPosition;
      varying highp vec2 vUv;
      void main () {
          highp vec4 p = vec4 ( position, 1.0 );
          worldPosition = ( modelMatrix * p ).xyz;
          gl_Position = projectionMatrix * modelViewMatrix * p;
      }
    `,
    fragmentShader: `
      uniform sampler2D map;
      uniform sampler2D map2;
      
      uniform vec3 camera;
      
      uniform vec3 placement;
      uniform vec3 placement2;
      
      uniform float intensity;
      uniform float intensity2;

      varying vec3 worldPosition;
      varying vec2 vUv;
      
      uniform vec2 resolution;
      
      const highp float PI = 3.141592653589;
      const highp float PI_HALF = PI / 2.;
      const highp float PI2 = PI * 2.;

      highp vec2 next_panorama_uv;
      highp float r;
      highp float theta;
      highp float phi;

      highp float offsetForM;
      
      float COLOR_MINIMAL_INTENSITY = 0.7;
      bool USE_BLUR = false;

      vec4 blur_func( sampler2D image, vec2 uv, vec2 resolution, float power_of ) {
          vec4 color = vec4(0.0);
          float power = 4455. * power_of;
          vec2 finallyUV;
          if( power_of > 0. ){
              finallyUV = uv - mod( 
                uv, 
                vec2( 
                  0.5 + cos( uv.x * PI * power ), 
                  0.5 + sin( uv.y * PI * power * power ) 
                ) / resolution * 2. 
              ) * power_of;
          } else {
              finallyUV = uv;
          }
          color = texture2D(image, finallyUV);
          return color;
      }
      
      vec4 blur_func_a( sampler2D image, in vec2 startUV,  vec2 iResolution, float defaultAffect ){
      
          float Pi_1 = 6.28318530718; // Pi*2
          vec4 startColor = texture2D( image, startUV ) * 0.2969069646728344;
          
          // GAUSSIAN BLUR SETTINGS {{{
            const float Pi = 6.28318530718; // Pi*2
            const float R2 = 1.0;
            const float Quality = 4.0; // BLUR QUALITY (Default 4.0 - More is better but slower)
            const float Directions = 16.0; // BLUR DIRECTIONS (Default 16.0 - More is better but slower)
            const float i_offset = 1.0 / Quality;
            const float Pi_Direction_offset =  Pi / Directions;
            const float Size = 2.5; // BLUR SIZE (Radius)
          // GAUSSIAN BLUR SETTINGS }}}
        
          vec2 Radius = Size / iResolution.xy;
          
          // Pixel colour
          vec4 Color = texture2D( image, startUV );
          
          // Blur calculations
          for( float d = 0.0; d < Pi; d += Pi_Direction_offset ){
              for( float i = i_offset; i <= R2; i += i_offset){
                  Color += texture2D( image, startUV + vec2( cos(d), sin(d) ) * Radius * i * defaultAffect );
              }
          }
          
          // Output to screen
          Color /= Quality * Directions - 15.0;
          return  Color;
      }

      vec4 get_pixel_onTexture_byParams( vec3 R, sampler2D texture22, float blurFactor ){

        next_panorama_uv = vec2( 0, 0 );

        r = length (R);
        theta = acos ( -R.y / r );
        next_panorama_uv.y =  theta / PI;

        phi = atan ( R.x, -R.z );

        next_panorama_uv.x = phi / PI2;
        next_panorama_uv.x = mod( next_panorama_uv.x, 1.0 );

        // check for needToJoinEdge
        highp float currentY_Normal = abs( 0.5 - next_panorama_uv.y );
        // highp float pi_for_use = ( PI * 0.3 ) +  PI * 0.5;
        highp float pi_for_use = ( PI * 4. );
        highp float uv_02 = sin( PI * .05 + ( PI * .5 * currentY_Normal ) );
        highp float edgesize = uv_02 / resolution.x;

            float minX = edgesize;
            float maxX = ( 1. - edgesize );
        
            // float minX = 2. / resolution.x;
            // float maxX = ( 1. - minX );
        
        bool needToJoinEdge = next_panorama_uv.x > maxX || next_panorama_uv.x < minX;

        float defaultAffect = 0.;
        
        vec4 color_finally;
        if( needToJoinEdge ){

            color_finally = blur_func( texture22, vec2( 0, next_panorama_uv.y ), resolution, defaultAffect );

        } else {
          if( blurFactor > 0.00 ){ 
            color_finally = blur_func( texture22, next_panorama_uv, resolution, defaultAffect );
          } else {
            color_finally = texture2D( texture22, next_panorama_uv );
          }
        }

        return color_finally;
      }

      void main () {

          highp vec4 finallyColor;

          highp vec4 tempColor;
          
          bool twoPanoramasOnly = true;

          vec4 color_1;
          vec4 color_2;
          
          highp vec3 R = worldPosition - placement;
          vec4 test = get_pixel_onTexture_byParams( R, map, intensity );
          color_1 = test * intensity;

          highp vec3 R2 = worldPosition - placement2;
          vec4 test2  = get_pixel_onTexture_byParams( R2, map2, intensity );
          color_2 = test2 * intensity2;

          tempColor = vec4( 
            color_1.r + color_2.r, 
            color_1.g + color_2.g, 
            color_1.b + color_2.b, 
            1.
          );
          
          finallyColor = tempColor;                    
          
          gl_FragColor = finallyColor;
      
      }
    `,
  });

  return material;
};

type ProjectionProps = JSX.IntrinsicElements['group'] & {
  active: boolean
  encoding?: THREE.TextureEncoding
  image: string
}

const prevData: { image?: THREE.Texture } = {image: undefined};

export const Projection = React.forwardRef<THREE.Group, ProjectionProps>((props, ref) => {
  const context = React.useContext(ProjectionContext);
  const _group = React.useRef<THREE.Group>(null);
  const texture = useTexture(props.image, (_t: any) => {
    const t = _t as THREE.Texture;
    if (props.encoding) {
      t.encoding = props.encoding;
    }
    t.magFilter = THREE.LinearFilter;
    t.minFilter = THREE.LinearFilter;
  });

  React.useEffect(() => {
    if (props.active && _group.current && texture) {
      context?.onActiveChange({
        image: texture,
        position: _group.current.position.clone(),
      });
    }
  }, [props.active, _group.current, texture]);

  return (
    <group ref={ _group } { ...props }>
      { props.children }
    </group>
  );
});

type ProjectionGroupProps = JSX.IntrinsicElements['group'] & {
  animation?: {
    speed: number
    easing: string
  }
  controls: OrbitControls
  environment: THREE.Object3D
  wireframe: boolean
  objectsWithCustomMaterials?: string[]
}

interface ProjectionContext {
  onActiveChange: (data: { image: THREE.Texture; position: THREE.Vector3 }) => void;
}

const ProjectionContext = React.createContext<ProjectionContext>(null!);

export const ProjectionGroup = React.forwardRef<THREE.Group, ProjectionGroupProps>((props, forwardedRef) => {
  const _environment = React.useRef<THREE.Object3D>(null!);
  const [shaderMaterial] = React.useState<THREE.ShaderMaterial>(createMaterial());
  const {
    camera,
    gl,
  } = useThree();
  const [cameraTraveler] = React.useState<CameraTraveler>(
    new CameraTraveler({
      camera: camera as any,
      controls: props.controls,
    }),
  );
  const [prevActivePoint, setPrevActivePoint] = React.useState<{ image: THREE.Texture; position: THREE.Vector3 } | undefined>(undefined);

  React.useEffect(() => {
    shaderMaterial.wireframe = props.wireframe;

    shaderMaterial.uniformsNeedUpdate = true;
    shaderMaterial.needsUpdate = true;
  }, [props.wireframe]);

  useFrame(() => {
    cameraTraveler.animate();
  });

  const initShader = () => {
    shaderMaterial.uniforms.resolution.value.set(
      gl.domElement.width * gl.pixelRatio,
      gl.domElement.height * gl.pixelRatio,
    );

    // shaderMaterial.wireframe = true
    _environment.current.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        const nameIntersection = props.objectsWithCustomMaterials?.find((el: string) => {
          const regEx = new RegExp(`^${el}$`);
          return regEx.test(child.name);
        });

        if (child.material && !nameIntersection) {
          child.material = shaderMaterial;
        }
      }
    });

    shaderMaterial.uniformsNeedUpdate = true;
    shaderMaterial.needsUpdate = true;
  };

  React.useEffect(() => {
    if (_environment.current) {
      initShader();
    }
  }, [_environment]);

  const travelToPointWithoutAnim: ProjectionContext['onActiveChange'] = async (data) => {
    const initCamPos = camera.position.clone();

    camera.position.copy(data.position.clone());

    const nextControls = data.position
      .clone()
      .add(initCamPos.sub(data.position)
        .normalize()
        .negate()
        .multiplyScalar(0.01))
      .add(new THREE.Vector3(0.001, 0, 0));

    if (camera.position.equals(nextControls)) {
      nextControls.add(new THREE.Vector3(0, 0, 0.1));
    }

    props.controls?.target.copy(nextControls);

    props.controls?.update();

    shaderMaterial.uniforms.map.value = data.image;
    shaderMaterial.uniforms.intensity.value = 1;
    shaderMaterial.uniforms.placement.value = data.position;

    shaderMaterial.uniformsNeedUpdate = true;
    shaderMaterial.needsUpdate = true;
  };

  const travelToPoint: ProjectionContext['onActiveChange'] = async (data) => {
    const {
      speed,
      easing,
    } = props.animation!;
    const {position} = data;
    const {target} = props.controls!;

    const distance = position.distanceTo(target);

    const nextControls = data.position
      .clone()
      .add(camera.position.clone()
        .sub(data.position)
        .normalize()
        .negate()
        .multiplyScalar(0.001));

    cameraTraveler.travel({
      end: {
        camera: data.position.toArray(),
        controls: nextControls.toArray(),
      },
      speed: (distance / 100) * speed,
      easing,
      onProgress: () => {
        const d1 = data;
        const d2 = prevActivePoint!;

        const p1 = d1.position.clone();
        const p2 = d2.position.clone();

        const p1d = p1.distanceTo(camera.position);
        const p2d = p2.distanceTo(camera.position);

        const totalDistance = p1d + p2d;

        const p1f = 1 / p1d / totalDistance;
        const p2f = 1 / p2d / totalDistance;

        const totalFactor = p1f + p2f;

        shaderMaterial.uniforms.map.value = d1.image;
        shaderMaterial.uniforms.intensity.value = p1f / totalFactor;
        shaderMaterial.uniforms.placement.value = p1;

        shaderMaterial.uniforms.map2.value = d2.image;
        shaderMaterial.uniforms.intensity2.value = p2f / totalFactor;
        shaderMaterial.uniforms.placement2.value = p2;

        shaderMaterial.uniforms.camera.value.copy(camera.position);
        shaderMaterial.uniformsNeedUpdate = true;
        shaderMaterial.needsUpdate = true;
      },
    });
  };

  const onActiveChange: ProjectionContext['onActiveChange'] = (data) => {
    if (prevData.image===data.image || !props.animation || !prevActivePoint) {
      travelToPointWithoutAnim(data);
    } else {
      travelToPoint(data);
    }

    prevData.image = data.image;

    setPrevActivePoint(data);
  };

  return (
    <group ref={ forwardedRef } { ...props }>
      <ProjectionContext.Provider value={ {onActiveChange} }>
        <primitive object={ props.environment } ref={ _environment }/>
        { props.children }
      </ProjectionContext.Provider>
    </group>
  );
});
