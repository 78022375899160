import React, { FC, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { extend, useFrame } from '@react-three/fiber';
import { Depth, LayerMaterial } from 'lamina';
import { Sampler } from '@react-three/drei';
import WindLayer from '../shaders/WindLayer';
// @ts-ignore
import Perlin from 'perlin.js';

Perlin.seed(Math.random());
extend({ WindLayer });

export const Grass: FC<any> = ({ children, strands = 60000, size = 0.5, speed = 0.005, ...props }: any) => {
  const meshRef = useRef<any>(null);
  const windLayer = useRef<any>(null);

  useEffect(() => {
    meshRef.current.geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
    meshRef.current.geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 0, 0.5));
    props.isRotatedGrass && meshRef.current.rotation.set(...props.rotate)
  }, []);

  const geomRef = useRef();
  useFrame(() => (windLayer.current.time += speed));

  return (
    <>
      {React.cloneElement(children, { ref: geomRef })}
      <instancedMesh ref={meshRef} args={[undefined, undefined, strands]} {...props}>
        <coneGeometry args={[0.05, 1.0, 2, 10, false, 0, Math.PI]}/>
        <LayerMaterial side={THREE.DoubleSide} lighting="physical" envMapIntensity={0.5}>
          <Depth colorA={props.colorA} colorB={props.colorB} near={0.14} far={1.52} mapping={'world'}/>
          {/*// @ts-ignore*/}
          <windLayer
            args={[{ mode: 'multiply' }]}
            colorA={'#ffffff'}
            colorB={'#acf5ce'}
            noiseScale={5}
            noiseStrength={5}
            length={1.2}
            sway={0.5}
            ref={windLayer}
          />
        </LayerMaterial>
      </instancedMesh>
      <group>
        <Sampler
          // @ts-ignore
          transform={({ position, normal, dummy: object }) => {
            position.sub(new THREE.Vector3(0, 0.5, 0));
            const p = position.clone().multiplyScalar(0.1);
            const n = Perlin.simplex3(...p.toArray());
            object.scale.setScalar(THREE.MathUtils.mapLinear(n, -1, 1, 0.3, size) * 0.5);
            object.position.copy(position.clone().add(props.shift));
            object.lookAt(normal.add(position.clone()));
            object.rotation.y += Math.random() - 0.5 * (Math.PI * 0.5);
            object.rotation.z += Math.random() - 0.5 * (Math.PI * 0.5);
            object.rotation.x += Math.random() - 0.5 * (Math.PI * 0.5);
            object.updateMatrix();
            return object;
          }}
          // @ts-ignore
          mesh={geomRef}
          instances={meshRef}
        />
      </group>
    </>
  );
};
