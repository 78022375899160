/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as THREE from 'three'
import Instruction from './instruction'
import EASING from './easing'
import { PerspectiveCamera } from 'three'
import { OrbitControls } from 'three-stdlib'

const DEFAULT_CONFIG = {
  onProgress: () => {},
  onEnd: () => {},
  speed: 1,
  time: null,
  // start: { camera: [0, 0, 1], controls: [0.000001, 0, 0] },
  end: { camera: [1, 0, 0], controls: [0, 0, 0.000001] },
  easing: 'outSine', // ? maybe 'linear'
}

interface IArrPosition {
  camera: [number, number, number]
  controls: [number, number, number]
}

interface ITravelConfig {
  start?: IArrPosition
  end: IArrPosition
  speed?: number
  onProgress?: (...args: any) => void
  onEnd?: (...args: any) => void
  easing?: string
}

interface ICameraTravelerConfig {
  controls: OrbitControls
  camera: PerspectiveCamera
}

class CameraTraveler extends THREE.EventDispatcher {
  inAction = false

  lastPointsConfig: IArrPosition | undefined

  animations = new Instruction(null, null)

  onstartControlsEnabledState = false

  camera: PerspectiveCamera | undefined

  controls: OrbitControls | undefined

  constructor(options: ICameraTravelerConfig) {
    super()

    this.controls = options.controls || undefined
    this.camera = options.camera || undefined
  }

  animate = (): void => {
    this.animations.run(null, null, null)
  }

  travel(config: ITravelConfig): Promise<any> {
    config = Object.assign({ ...DEFAULT_CONFIG }, config)

    if (!config.start) {
      config.start = {
        camera: this.camera!.position.toArray(),
        controls: this.controls!.target.toArray(),
      }
    }
    /**

            config => {

                start: {
                    camera: [ x, y, z ],
                    controls: [ x, y, z ],
                },

                end: {
                    camera: [ x, y, z ],
                    controls: [ x, y, z ],
                },

                speed: travelSpeed,

                onProgress: ( alphaWithEasing, alphaLinear ) => {

                    onProgressCallback function;

                }

            }

        */

    const { onProgress, onEnd, start, speed, end, easing } = config

    return new Promise((resolve, reject) => {
      if (this.inAction) {
        reject(new Error('camera ready in action'))
      } else {
        // @ts-ignore
        if (!end.points) {
          this.dispatchEvent({ type: 'scene:points.changed' })
        }

        this.dispatchEvent({ type: 'camera:start.travel' })

        this.cameraTravel({
          start,
          end,
          speed,
          easing,

          onProgress: (alpha: number) => {
            onProgress!(alpha)
          },

          onEnd: (eventResult: any) => {
            const finalEvent = {
              from: { sceneData: eventResult.start },
              to: { sceneData: eventResult.end },
              steps: eventResult.steps,
            }

            this.lastPointsConfig = end

            this.dispatchEvent({ type: 'camera:end.travel' })

            this.dispatchEvent({ type: 'scene:change.position' })

            onEnd!(eventResult)

            this.onEnd()

            resolve(finalEvent)
          },
        })
      }
    })
  }

  onEnd(): void {
    this.inAction = false
  }

  onStart(): void {
    this.inAction = true
  }

  cameraTravel(config: any): void {
    this.onStart()

    const start = {
      camera: new THREE.Vector3().fromArray(config.start.camera),
      controls: new THREE.Vector3().fromArray(config.start.controls),
    }

    const end = {
      camera: new THREE.Vector3().fromArray(config.end.camera),
      controls: new THREE.Vector3().fromArray(config.end.controls),
    }

    const curveCameraArray = [start.camera.clone()]
    const curveControlsArray = [start.controls.clone()]

    if (config.start.crossPoints) {
      config.start.crossPoints.out.forEach((nextCrossPoint: any) => {
        curveCameraArray.push(new THREE.Vector3().fromArray(nextCrossPoint.camera))
        curveControlsArray.push(new THREE.Vector3().fromArray(nextCrossPoint.controls))
      })
    }

    if (config.end.crossPoints) {
      config.end.crossPoints.in.forEach((nextCrossPoint: any) => {
        curveCameraArray.push(new THREE.Vector3().fromArray(nextCrossPoint.camera))
        curveControlsArray.push(new THREE.Vector3().fromArray(nextCrossPoint.controls))
      })
    }

    curveCameraArray.push(end.camera.clone())
    curveControlsArray.push(end.controls.clone())

    const nextEasingFoo = config.easing in EASING ? config.easing : 'linear'

    const curveCamera = new THREE.CatmullRomCurve3(curveCameraArray)
    const curveControls = new THREE.CatmullRomCurve3(curveControlsArray)

    const maxFrames = 180
    const calculatedFrames = Math.ceil(config.time / (1000 / 60) || start.camera.distanceTo(end.camera) / config.speed)
    const frames = calculatedFrames > maxFrames ? maxFrames : calculatedFrames

    let currentFrame = 0

    const travelCameraPoints: any[] = []
    const travelControlsPoints: any[] = []

    try {
      EASING[nextEasingFoo](frames / 2, frames)
    } catch (err) {
      console.error(err)
      throw err
    }

    for (let i = 0; i < frames; i += 1) {
      travelCameraPoints.push(curveCamera.getPoint(EASING[nextEasingFoo](i, frames)))
      travelControlsPoints.push(curveControls.getPoint(EASING[nextEasingFoo](i, frames)))
    }

    this.animations.add('cameraTravel', () => {
      const linearAlpha = currentFrame / frames

      const currentFrameAlpha = EASING[nextEasingFoo](currentFrame, frames)

      if (currentFrame < frames) {
        this.camera?.position.copy(travelCameraPoints[currentFrame])
        this.controls?.target.copy(travelControlsPoints[currentFrame])

        config.onProgress(currentFrameAlpha, linearAlpha)

        this.controls?.update()
      } else {
        this.camera?.position.copy(end.camera.clone())
        this.controls?.target.copy(end.controls.clone())

        this.animations.drop('cameraTravel')

        config.onEnd({
          start,
          end,
          steps: frames,
        })

        this.onEnd()
      }

      currentFrame += 1
    })
  }
}

export { CameraTraveler }
